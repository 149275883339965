/* eslint-disable class-methods-use-this */
import React from 'react';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import DataTable from '../Components/Data-Table';
import '../Scss/dashboard.scss';
import { APIS } from '../api/api';
import Input from '../Components/Input';
import { districts } from '../constants/district';
import DashboardFilter from '../Components/Dashboard-Filter';
import { checkValidValue, validateAdminAccess } from '../Utils/CommonFunction';
import ReactPaginate from 'react-paginate';
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      statusElements: [
        { header: 'TOTAL MEMBERS', value: '10,000' },
        { header: 'PENDING APPROVAL', value: '10,000' },
        { header: 'REJECTED', value: '10,000' },
        { header: 'RENEWAL PENDING', value: '10,000' },
      ],
      filterElements: [
        { label: 'User Id', key: 'id' },
        { label: 'First Name', key: 'first_name' },
        { label: 'Last Name', key: 'last_name' },
        { label: 'Designation', key: 'designation' },
        {
          label: 'Division',
          type: 'dropdown',
          options: districts,
          key: 'division',
          restrictedAccess: !validateAdminAccess(),
        },
        { label: 'Contact No', key: 'contact_number' },
      ],
      usersDetail: [],
    };
    this.getStatusElement = this.getStatusElement.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.currentPage = 1;
  }

  componentDidMount() {
    const userDetail = JSON.parse(localStorage.getItem('userdetails'));
    const userData = userDetail.userData;
    this.setState({ userData }, () => {
      this.getDashboardData();
    });
  }

  getDashboardData() {
    this.setState({ loader: true });
    const { userData } = this.state;
    const APIURL = APIS.get_url('GET_USERDETAILS_LIST');
    console.log(this.currentPage);
    const params = { page: this.currentPage, size: 50 };
    Axios({
      method: 'get',
      url: APIURL,
      responseType: 'json',
      timeout: 60 * 1000,
      params,
      headers: {
            'X-Username': userData ? userData.name : '', // Corrected header name
        },
    }).then((res) => {
      this.setState({ usersDetail: res.data, loader: false });
    });
  }
  onFilterChange(value, key) {
    this.setState({ [key]: value });
  }

  getStatusElement() {
    const { statusElements } = this.state;
    return statusElements.map((element) => (
      <div className='status-element-holder'>
        <div className='status-element'>
          <div className='status-header'>{element.header}</div>
          <div className='status-value'>{element.value}</div>
        </div>
      </div>
    ));
  }

  filterData() {
    this.setState({ loader: true });
    const {
      id,
      first_name,
      last_name,
      designation,
      division,
      contact_number,
      userData,
    } = this.state;
    const APIURL = APIS.get_url('GET_FILTERED_USERDETAILS_LIST');
    Axios({
      method: 'get',
      url: APIURL,
      params: {
        id: checkValidValue(id),
        first_name: checkValidValue(first_name),
        last_name: checkValidValue(last_name),
        designation: checkValidValue(designation),
        division: checkValidValue(division),
        contact_number: checkValidValue(contact_number),
        page: this.currentPage,
        size: 50,
      },
      responseType: 'json',
      timeout: 60 * 1000,
      headers: {
        'X-Username': userData ? userData.name : '',
      },
    }).then((res) => {
      this.setState({ usersDetail: res.data, loader: false });
    });
  }

  selectedRow(userId) {
    this.props.history.push(`/user-detail/${userId}`);
  }

  onChangeUserStatus(userId, status, type) {
    this.setState({ loader: true });
    const { userData } = this.state;
    const params = {};
    let updateVal = false;
    console.log("here")
    params['user_status'] = status
    params['user_type'] = type
    updateVal = true;
    console.log(params)
    if (
      (userData && userData.user_type === 'admin') ||
      userData.user_type === 'super_admin'
    ) {
      // params['admin_approve_status'] = status;
      updateVal = true;
    } else if (userData && userData.user_type === 'central_team') {
      // params['central_team_approve_status'] = status;
      updateVal = true;
    }
    if (updateVal) {
      const APIURL = APIS.get_url('UPDATE_USER_DETAILS', userId);
      Axios({
        method: 'put',
        url: APIURL,
        data: params,
        responseType: 'json',
        timeout: 60 * 1000,
        headers: {
          'X-Username': userData ? userData.name : '',
        },
      }).then((res) => {
        this.setState({ loader: false });
        this.getDashboardData();
        // this.setState({ usersDetail: res.data });
      });
    }
  }

  render() {
    const { usersDetail, filterElements } = this.state;
    const pageSize = Math.ceil(usersDetail.total / 50);
    return (
      <div className='dashboard'>
        {this.state.loader && <div className='loader'></div>}
        {/* <div className='status-holder'>{this.getStatusElement()}</div> */}
        <div className='filter-holder'>
          <DashboardFilter
            data={{ filterElements }}
            methods={{ onFilterChange: this.onFilterChange }}
          />
          <button className='btn primary' onClick={() => this.filterData()}>
            Filter
          </button>
        </div>
        <DataTable
          tableDatas={usersDetail?.data || []}
          onRowSelect={(userId) => this.selectedRow(userId)}
          onChangeUserStatus={(userId, status) => {
            this.onChangeUserStatus(userId, status);
          }}
        />
        <div className='w-100 d-flex-center'>
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageSize || 0}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(page) => {
              if (page.selected) {
                this.currentPage = page.selected + 1;
                this.filterData();
              }
            }}
            containerClassName={'pagination'}
            activeClassName={'active'}
            previousClassName={'nav'}
            nextClassName={'nav'}
            initialPage={this.state.currentPage - 1}
          />
        </div>
      </div>
      // <div className="id-holder">
      //   <div className="id-front">
      //     <div className="id-header">
      //       <h2>ATCHAYA WELFARE FUND MEMBERSHIP CARD</h2>
      //     </div>

      //     <div className="id-values-holder">
      //       <div className="image-holder">
      //         <img src="https://res.cloudinary.com/hfaeeiom3/image/upload/profile-image/22.jpg" />
      //       </div>
      //       <div className="w-75">
      //         <div className="w-50">
      //           <div className="label">
      //             AWF No: <span className="value">001</span>
      //           </div>
      //         </div>
      //         <div className="w-50">
      //           <div className="label">
      //             GDP / CPS No: <span className="value">45917CR</span>
      //           </div>
      //         </div>
      //         <div className="w-50">
      //           <div className="label">
      //             Name: <span className="value">Muthusamy R</span>
      //           </div>
      //         </div>
      //         <div className="w-50">
      //           <div className="label">
      //             S/o., D/o., W/o: <span className="value">Ramaiah</span>
      //           </div>
      //         </div>
      //         <div className="w-50">
      //           <div className="label">
      //             Branch Name: <span className="value">Arimalam</span>
      //           </div>
      //         </div>
      //         <div className="w-50">
      //           <div className="label">
      //             District: <span className="value">Pudukkottai</span>
      //           </div>
      //         </div>
      //         <div className="w-50">
      //           <div className="label">
      //             D.O.B: <span className="value">24.05.1964</span>
      //           </div>
      //         </div>
      //         <div className="w-50">
      //           <div className="label">
      //             D.O.Rtd: <span className="value">24.05.2024</span>
      //           </div>
      //         </div>
      //         <div className="w-50">
      //           <div className="label">
      //             D.O. Enrolment: <span className="value">24.05.2000</span>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      //   <div className="id-back">
      //     <div className="id-header">
      //       <h2>TNEB AYKKIA THOZHILALAR WELFARE TRUST</h2>
      //       <h5> Regd No. 681/4/2000 Chennai</h5>
      //     </div>
      //     <div className="address">
      //       <p>H.Q: Room No: 6 TNEB H.Qtrs Complex,</p>
      //       <p>No. 144, Annasalai, Chennai 600002.</p>
      //       <p>Ph : 044 - 28527640</p>
      //     </div>
      //     <div className="id-values-holder">
      //       <div className="w-50">
      //         <div className="center label">Nominee Name</div>
      //       </div>
      //       <div className="w-50">
      //         <div className="center label">Relationship</div>
      //       </div>
      //       <div className="w-50 brd-btm">
      //         <div className="center value">Selvi</div>
      //       </div>
      //       <div className="w-50 brd-btm">
      //         <div className="center value">Wife</div>
      //       </div>
      //       <div className="w-50 brd-btm">
      //         <div className="center value">Leela</div>
      //       </div>
      //       <div className="w-50 brd-btm">
      //         <div className="center value">Daughter</div>
      //       </div>
      //       <div className="w-50 ">
      //         <div className="center value">Santhosh</div>
      //       </div>
      //       <div className="w-50">
      //         <div className="center value">Son</div>
      //       </div>
      //       <div className="w-50">
      //         <div className="center label">Secretary / TNEB ATWT</div>
      //       </div>
      //       <div className="w-50">
      //         <div className="center label">Chairman / TNEB ATWT</div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default withRouter(Dashboard);
