import { toast } from 'react-toastify';
import React from 'react';
import Toast from '../Components/Toast';

export function checkValidValue(value) {
  if (value) return value;
  else return undefined;
}

export function validateAdminAccess() {
  const userDetail = JSON.parse(localStorage.getItem('userdetails'));
  const userData = userDetail.userData;
  return userData.user_type === 'super_admin' || userData.user_type === 'admin'
    ? true
    : false;
}

export const toastEmitter = (message, toastType, options) => {
  switch (toastType) {
    case 'error':
      toast.error(<Toast message={message} type={toastType} />, options);
      break;
    default:
      toast(<Toast message={message} type={toastType} />, options);
  }
};

export const formatDate = (date) => {
  if (date) {
    const splittedDob = date.split('-');
    return splittedDob.reverse().join('-');
  }
  return ''
};
