import React from "react";
export const IconSvgPathConstants = {
  info: (
    <g id="Group_4547" data-name="Group 4547" transform="translate(-97 -236)">
      <circle
        id="Ellipse_291"
        data-name="Ellipse 291"
        cx="10"
        cy="10"
        r="10"
        transform="translate(97 236)"
        fill="#989898"
      />
      <text
        id="i"
        transform="translate(105 250)"
        fill="#fff"
        font-size="14"
        font-family="Times-BoldItalic, Times"
        font-weight="700"
        font-style="italic"
      >
        <tspan x="0" y="0">
          i
        </tspan>
      </text>
    </g>
  ),
  close: (
    <path
      id="Path_369"
      data-name="Path 369"
      d="M17.074,2.926a10,10,0,1,0,0,14.149A10.016,10.016,0,0,0,17.074,2.926Zm-3.129,11.02a.769.769,0,0,1-1.088,0L10,11.088,7.007,14.081a.769.769,0,0,1-1.088-1.088L8.912,10,6.055,7.143A.769.769,0,0,1,7.143,6.055L10,8.912l2.721-2.721a.769.769,0,0,1,1.088,1.088L11.088,10l2.857,2.857A.769.769,0,0,1,13.945,13.945Z"
      transform="translate(0 0)"
      fill="#aaa"
    />
  ),
};
