import React, { useState } from "react";
import DropDown from "../dropdown";
import TextField from "@material-ui/core/TextField";

export default function Input(props) {
  const { shrink, value, element, valueChange } = props;
  const [currentValue, setCurrentValue] = useState(value);

  const handleChange = (event) => {
    setCurrentValue(event.target.value);
    valueChange(event.target.value)
  };

  const getFormElement = () => {
    switch (element.type) {
      case "dropdown":
        return (
          <DropDown
            dropDownOptions={element.options}
            label={element.label}
            handleOptionChange={(e) => handleChange(e)}
            value={currentValue}
          />
        );
      default:
        return (
          <TextField
            label={element.label}
            type={element.type}
            value={currentValue}
            variant="outlined"
            onChange={(e) => handleChange(e)}
            size="small"
            InputLabelProps={{ shrink: shrink }}
          />
        );
    }
  };

  return (
    <React.Fragment>
      {getFormElement()}
    </React.Fragment>
  );
}
