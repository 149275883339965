export const divisions = [
  {
    sections: [
      'AGARAM',
      'KOLATHUR',
      'TAGORE NAGAR',
      'PERAMBUR/WEST',
      'THIRU VI KA NAGAR',
      'SIDCO NAGAR',
      'AYANAVARAM',
      'ICF',
      'RAJAJI NAGAR',
      'SEMBIUM',
      'VILLIVAKKAM',
      'PERAMBUR/EAST',
      'LAKSHMIPURAM',
      'POOMPUGAR NAGAR',
      'KODUNGAIYUR',
      'GANDHI NAGAR',
      'RETTERI',
      'ARANI',
      'PANJETTY',
      'ANDARKUPPAM',
      'KAVARAIPETTAI',
      'SIPCOT',
      'PONNERI/TOWN',
      'PONNERI SS O&M',
      'GUMMIDIPOONDI TOWN',
      'DEVAMBEDU',
      'PULICAT',
      'POOVALAMBEDU',
      'ELAVOOR',
      'IRULIPATTU',
      'PONNERI/SOUTH',
      'MEDUR',
      'PONNERI/WEST',
      'PONNERI/EAST',
      'SIPCOT - II',
      'MADHARAPAKKAM',
      'GUMMMIDIPOONDI RURAL',
      'TONDIARPET SS',
      'NAPPALAYAM',
      'MANALI',
      'ENNORE',
      'KALADIPET',
      'THIRUVOTTIYUR O&M',
      'NEWWASHERMEN PET',
      'TONDIARPET',
      'KORRUKKUPET',
      'THIRUVETRIYUR SS',
      'ERNAVOOR',
      'MANALI NEW TOWN',
      'KALADIPET SS',
      'MELUR',
      'MINJUR WEST',
      'MINJUR EAST',
      'MINJUR TOWN',
      'SATHANGADU',
      'KALMANDAPAM',
      'MKB NAGAR',
      'VALLALAR NAGAR',
      'KANNA DASAN NAGAR',
      'MADHAVARAM I',
      'VYASARPADI',
      'STANLEY',
      'ROYAPURAM',
      'VOC NAGAR',
      'RK NAGAR',
      'MADHAVARAM II',
      'MR NAGAR',
      'MATHUR',
      'VADAPERUMBAKKAM',
      'MANJAMBAKKAM',
      'KRISHNAMURTHY NAGAR',
    ],
    edc: 'CHENNAI EDC/NORTH',
  },
  {
    sections: [
      'K.K.NAGAR/EAST',
      'K.K.NAGAR/WEST',
      'MGR NAGAR',
      'ASHOK NAGAR/EAST',
      'ASHOK NAGAR/WEST',
      'K.K.NAGAR/SOUTH',
      'KODAMBAKKAM',
      'VADAPALANI',
      'CHOOLAIMEDU',
      'RANGARAJAPURAM',
      'VALASARAVAKKAM',
      'VIRUGAMBAKKAM',
      'ALWARTHIRUNAGAR',
      'CHINMAYANAGAR',
      'SALAIGRAMAM',
      'DASARTHAPURAM',
      'ALAGIRINAGAR',
      'GUINDY',
      'RAJBHAVAN',
      'RAMAPURAM',
      'MANAPAKKAM',
      'NANDAMBAKKAM',
      'MUGALIVAKKAM',
      'ALANDUR',
      'ST.THOMAS MOUNT',
      'NANGANALLUR',
      'MADIPAKKAM',
      'MOOVARASAMPET',
      'ADAMBAKKAM',
      'THILLAI GANGA NAGAR',
      'VANUVAMPET',
      'PUZHUTHIVAKKAM',
      'PORUR-I ',
      'PORUR-II ',
      'PORUR RURAL ',
      'MADANATHAPURAM ',
      'KARAMBAKKAM ',
      'GERUGAMBAKKAM ',
      'MANGADU ',
      'AYYAPPANTHANGAL ',
      'KUMANANCHAVADI',
      'PARANIPUTHUR ',
      'POONAMALLEE NORTH ',
      'POONAMALLEE SOUTH ',
      'KUNDRATHUR TOWN',
      'KUNDRATHUR RURAL ',
      'NAZARATHPET ',
      'PARIVAKKAM ',
      'KOVOOR ',
      'THIRUMUDIVAKKAM TOWN ',
      'THIRUMUDIVAKKAM RURAL ',
      'I.E.THIRUMUDIVAKKAM ',
      'SOMANGALAM ',
    ],
    edc: 'CHENNAI EDC/SOUTH-I',
  },
  {
    sections: [
      'SASTHRINAGAR',
      'INDIRANAGAR',
      'PALAVAKKAM',
      'VELACHERRY/WEST',
      'THARAMANI',
      'VELACHERRY/EAST',
      'ADAYAR',
      'BESANT NAGAR',
      'KOTTIVAKKAM',
      'VELACHERY CENTRAL',
      'THIRUVANMIYUR',
      'NEELANKARAI',
      'ENJAMBAKKAM',
      'PANNAIYUR',
      'CHINNAMALAI',
      'PALLIKARANAI',
      'MADAMBAKKAM',
      'CHITHALAPAKKAM',
      'MUDICHUR',
      'POZHICHALUR',
      'MEDAVAKKAM',
      'KOVILAMBAKKAM',
      'NEHRU NAGAR',
      'PALLAVARAM/WEST',
      'PERUNGALATHUR',
      'TAMBARAM/WEST',
      'NEW COLONY',
      'HASTHINAPURAM',
      'CHITALAPAKKAM',
      'ANANGAPUTHUR',
      'TAMBARAM/EAST',
      'SELAIYUR',
      'RADHA NAGAR',
      'PALLAVARAM/EAST',
      'CHROMPET',
      'KADAPPERI',
      'SEMBAKKAM',
      'PAMMAL',
      'KEELKATALAI',
      'GOWRIVAKKAM',
      'TIDEL PARK',
      'KANDANCHAVADI',
      'I.E.PERUNGUDY',
      'PERUNGUDY/NORTH',
      'PERUNGUDI/SOUTH',
      'VSI ESTATE',
      'THORAIPAKKAM',
      'M.C.N. NAGAR',
      'KARAPPAKKAM',
      'CHEMMENCHERY',
      'VSI ESTATE/RURAL',
      'VSI ESTATE/CENTRAL',
      'I.E.PERUNGUDY/RURAL',
      'KURINCHI NAGAR',
      'SHOLINGANALLLUR/NORTH',
      'SIRUSERI',
      'SHOLINGANALLUR',
    ],
    edc: 'Chennai EDC/South-II',
  },
  {
    sections: [
      'ANNA NAGAR/CENTRAL',
      'MMDA',
      'SHANTHI COLONY',
      'ANNA NAGAR/WEST',
      'SHENOY NAGAR',
      'MAHALINGAPURAM',
      'KOYAMBEDU MARKET',
      'KOYAMBEDU',
      'ANNA NAGAR/NORTH',
      'AMINJIKARAI',
      'CHETPUT',
      'ARUMBAKKAM',
      'THIRUVALLESWARAR NAGAR (Thirumangalam)',
      'VANAGARAM',
      'MADURAVOYAL/NORTH',
      'PORUR GARDEN',
      'MADURAVOYAL/SOUTH',
      'ALAPPAKKAM',
      'NERKUNDRAM WEST',
      'IE/NORTH',
      'ATHIPET',
      'IE/CENTRAL',
      'NOLAMBUR',
      'PATTARAVAKKAM',
      'PULIAMBEDU',
      'JJNAGAR/NORTH',
      'MANGALAPURAM',
      'IE SOUTH',
      'ANNAI NAGAR',
      'MENAMBEDU',
      'KALLIKUPPAM',
      'PONNIAMMAN NGR',
      'MANNURPET',
      'BANU NAGAR',
      'ORAGADAM',
      'PADI',
      'JJNAGAR SOUTH',
      'JJNAGAR WEST',
      'AMBATTUR/ CENTRAL',
      'JJNAGAR/CENTRAL',
      'KORATTUR',
      'THIRUVENKATANAGAR',
      'KRISHNA NAGAR',
      'ADAYALAMPET',
      'AYYAPAKKAM',
      'VELLAPPANCHAVADI',
      'THIRUVERKADU',
      'AYANAMBAKKAM',
      'PONDESWARAM',
      'ALAMATHY',
      'SHOLAVARAM',
      'REDHILLS I',
      'REDHILLS II',
      'VILLANKADUPAKKAM',
      'KOVILPATHAGAI',
      'SOTHUPERUMBEDU',
      'ARUMANTHAI',
      'PADIYANALLUR',
      'KAMARAJ NAGAR',
      'PATTABIRAM',
      'PUZHAL',
      'THIRUMULLAIVOYAL',
      'GRANDLYON',
      'SEKKADU',
      'TNHB/PATTABIRAM',
      'AVADI/NORTH',
      'AVADI/SOUTH',
      'SENTHIL NAGAR',
      'PARUTHIPET',
      'MITTANAMALLEE',
      'VEERAPURAM',
      'SIDCO THIRUMULLAIVOYAL',
    ],
    edc: 'CHENNAI EDC/WEST',
  },
  {
    sections: [
      'ANNA SALAI',
      'G.P.ROAD',
      'TVPET',
      'GOVT.ESTATE',
      'CHEPAUK',
      'CHINDADIRIPET',
      'PUDUPET',
      'PARK TOWN',
      'FLOWER BAZAAR',
      'ESPLANADE',
      'S.K.NAGAR',
      'MUTHIALPET',
      'MANNADY',
      'EGMORE',
      'PANTHEON ROAD',
      'PERIAMET',
      'VEPERY',
      'KILPAUK',
      'KELLYS',
      'PURASAIWALKAM',
      'NAMMALWARPET',
      'PULIANTHOPE',
      'CHOOLAI',
      'COOKS ROAD',
      'OTTERI',
      'SOWCARPET/EAST',
      'SOWCARPET/WEST',
      'KONDITHOPE',
      'SEVEN WELLS',
      'SOWCARPET/CENTRAL',
      'MYLAPORE/WEST',
      'MYLAPORE/EAST',
      'ALWARPET',
      'LUZ',
      'SANTHOME',
      'MANDAVELI',
      'FORE SHORE ESTATE',
      'ICE HOUSE',
      'BALAJI NAGAR',
      'TRIPLICANE',
      'WHITES ROAD',
      'NUNGAMBAKKAM',
      'THOUSAND LIGHTS',
      'COLLEGE ROAD',
      'GOPALAPURAM',
      'ROYAPETTAH-II',
      'T.NAGAR/EAST',
      'T.NAGAR/NORTH',
      'T.NAGAR/CENTRAL',
      'TEYNAMPET',
      'NANDANAM',
      'R.A.PURAM',
      'SAIDAPET/EAST',
      'CIT NAGAR I',
      'CIT NAGAR II',
      'WEST MAMBALAM-I',
      'WEST MAMBALAM-II',
      'USMAN ROAD',
      'T.NAGAR/SOUTH',
      'SAIDAPET/WEST',
      'SASTHRINAGAR',
      'INDIRANAGAR',
      'PALAVAKKAM',
      'VELACHERRY/WEST',
      'THARAMANI',
      'VELACHERRY/EAST',
      'ADAYAR',
      'BESANT NAGAR',
      'KOTTIVAKKAM',
      'VELACHERY CENTRAL',
      'THIRUVANMIYUR',
      'NEELANKARAI',
      'ENJAMBAKKAM',
      'PANNAIYUR',
      'CHINNAMALAI',
      'PALLIKARANAI',
      'MADAMBAKKAM',
      'CHITHALAPAKKAM',
      'MUDICHUR',
      'POZHICHALUR',
      'MEDAVAKKAM',
      'KOVILAMBAKKAM',
      'NEHRU NAGAR',
      'PALLAVARAM/WEST',
      'PERUNGALATHUR',
      'TAMBARAM/WEST',
      'NEW COLONY',
      'HASTHINAPURAM',
      'CHITALAPAKKAM',
      'ANANGAPUTHUR',
      'TAMBARAM/EAST',
      'SELAIYUR',
      'RADHA NAGAR',
      'PALLAVARAM/EAST',
      'CHROMPET',
      'KADAPPERI',
      'SEMBAKKAM',
      'PAMMAL',
      'KEELKATALAI',
      'GOWRIVAKKAM',
      'TIDEL PARK',
      'KANDANCHAVADI',
      'I.E.PERUNGUDY',
      'PERUNGUDY/NORTH',
      'PERUNGUDI/SOUTH',
      'VSI ESTATE',
      'THORAIPAKKAM',
      'M.C.N. NAGAR',
      'KARAPPAKKAM',
      'CHEMMENCHERY',
      'VSI ESTATE/RURAL',
      'VSI ESTATE/CENTRAL',
      'I.E.PERUNGUDY/RURAL',
      'KURINCHI NAGAR',
      'SHOLINGANALLLUR/NORTH',
      'SIRUSERI',
      'SHOLINGANALLUR',
      'POOTHAMPATTY',
      'GUZILIAMPARAI / RURAL',
      'ERIODU I',
      'KOVILUR',
      'GUZILIAMPARAI / TOWN',
      'CHATRAPATTY',
      'VALLIPATTY',
      'SUKKAMPATTY',
      'PALAYAM',
      'KULATHUR',
      'ANGU NAGAR',
      'KAMACHIPURAM',
      'NGO COLONY',
      'THADICOMBU',
      'BUS STAND/DINDIGUL',
      'NAGAL NAGAR',
      'ROCKFORT',
      'NEHRUJI NAGAR',
      'BEGAMPUR',
      'PANDIAYN NAGAR',
      'PONNAGARAM',
      'GANDHIGRAM',
      'GOPALPATTY',
      'NATHAM /RURAL / WEST',
      'EAST/ DINDIGUL',
      'BALAKRISHNAPURAM',
      'SILUVATHUR',
      'SIRUKUDY',
      'TAMARAIPADY',
      'VADAMADURAI/TOWN',
      'RURAL/VADAMADURAI',
      'SENDURAI',
      'N.PANJAMPATTY',
      'SEMPATTY',
      'AYYALUR',
      'V.KURUMBAPATTY',
      'CHINNALAPATTY',
      'NATHAM /TOWN',
      'KODAIKANAL/RURAL',
      'PANNAIKADU',
      'KODAIKANAL / WEST',
      'KODAIKANAL / EAST',
      'THANDIKUDY',
      'AYYAMPALAYAM',
      'AMMAYA-NAICKANOOR',
      'BATLAGUNDU/WEST',
      'VIRUVEDU',
      'SITHAYANKOTTAI',
      'RAMARAJAPURAM',
      'NILAKOTTAI / RURAL',
      'BATLAGUNDU/EAST',
      'NILAKOTTAI/ TOWN',
      'BATLAGUNDU/ TOWN',
      'KANNIVADY',
      'KALLIMANDAYAM/ EAST',
      'AMBILIKAI',
      'K.KEERANUR',
      'KALLIMANDAYAM/ NORTH',
      'PANNAIPATTY',
      'VIRUPATCHI',
      'MOOLACHATRAM',
      'MARKAMPATTY',
      'KETHAIYURUMBU',
      'EDAYAKOTTAI',
      'KALLIMANDAYAM/ WEST',
      'ODDANCHATRAM/ TOWN',
      'VELLANAMPATTY',
      'SENANKOTTAI',
      'KALWARPATTY',
      'VEDASANDUR / TOWN',
      'NEIKKARAPATTY',
      'AYAKUDY',
      'SAMINATHAPURAM',
      'PAPPAMPATTY',
      'PALANI /RURAL / SOUTH',
      'KEERANUR',
      'CHATRAPTTY/WEST',
      'CHATRAPTTY/EAST',
      'C.K.PUTHUR',
      'THOPPAMPATTY',
      'PALANI /NORTH',
      'ADIVARAM',
      'PALANI /TOWN',
      'ETHAMOZHY',
      'RAJAKKAMANGALAM',
      'PARVATHYPURAM',
      'KOTTARAM',
      'MYLAUDY',
      'KANYAKUMARI',
      'KEEZHAKRISHNANPUTHOOR',
      'SUCHINDRAM',
      'THENGAMPUDUR',
      'ARALVAIMOZHY',
      'AZHAGIAPANDIPURAM',
      'BOOTHAPANDY',
      'THOVALAI',
      'THUCKULAY - 1',
      'THUCKULAY /TOWN II',
      'THICKANAMCODE',
      'VELLICHANDHAI',
      'COLACHEL I',
      'COLACHEL II',
      'ERANIEL',
      'MANAVALAKURICHY',
      'PARASERY',
      'KULASEKARAM RURAL',
      'KULASEKARAM URBAN',
      'THIRUVATTAR',
      'KUZHITHURAI',
      'MARTHANDAM',
      'ARUMANAI',
      'KALIYAKKAVILAI',
      'KALIYAL',
      'KANNUMAMOODU',
      'PUTHENCHANDAI',
      'PUTHUKADAI',
      'KARUNGAL',
      'KILLIYOOR',
      'KOLLENCODE',
      'ERAVIPUTHOORKADAI',
      'NAMBOLY',
      'PALLIYADI',
      'SOORIYACODE',
    ],
    edc: 'CHENNAI EDC/CENTRAL',
  },
  {
    sections: [
      'KANIYALAMPATTY',
      'KARUR',
      'MANMANGALAM',
      'OTHAKADAI',
      'PULIYUR',
      'UPPIDAMANGALAM',
      'VANGAL',
      'VELLIANAI',
      'GANDHIGRAMMAM',
      'KARUR /URBAN / SOUTH',
      'KARUR/URBAN/NORTH',
      'PULIYUR /WEST',
      'THANTHONIMALI',
      'URBAN/WEST/KARUR',
      'VENGAMEDU',
      'ANDIPATTY',
      'ARAVAKURUCHI',
      'CDPURAM',
      'CDPURAM / RURAL',
      'CHATRAM',
      'EASANATHAM',
      'K.PARAMATHI',
      'KARUR /RURAL',
      'KARVAZHI',
      'MALAIKOVILUR',
      'NOYYAL',
      'PALLAPATTY/RURAL/ NORTH',
      'PALLAPATTY/RURAL/ SOUTH',
      'PAVITHRAM',
      'THENNILAI',
      'KARUR /SOUTH',
      'PALLAPATTY /URBAN',
      'THOTTAKURICHI',
      'VELAYUTHAM-PALAYAM',
      'AYERMALAI /NORTH',
      'AYERMALAI /SOUTH',
      'C.M.PATTY',
      'CHINNAPANAIYANUR',
      'CM.PATTY / RURAL / SOUTH',
      'CMPATTY / RURAL',
      'KAVALKARANPATTY',
      'KOSUR',
      'MAYANUR',
      'MAYANUR / RURAL',
      'NACHALUR',
      'PALAVIDUTHI',
      'PANICKAMPATTY',
      'PANJAPATTY',
      'THOGAMALAI / NORTH',
      'THOGAMALAI /SOUTH',
      'KULITHALAI',
      'KADACHANENDAL',
      'KARUPPAYURANI',
      'OTHAKKADAI',
      'KARUNGALAKUDY',
      'KEELAIYUR',
      'MELUR /RURAL / WEST',
      'MELUR /TOWN',
      'KEELAMADHUR',
      'SHOLAVANDAN / RURAL',
      'AYYANKOTTAI',
      'VICKIRA-MANGALAM',
      'PALAMEDU',
      'ALANGANALLUR',
      'KOODALNAGAR',
      'SAMAYANALLUR / RURAL',
      'VADIPATTY',
      'SHOLAVANDAN / TOWN',
      'INDL.ESTATE/ KAPPALUR',
      'SAPTUR',
      'TIRUMANGALAM / SOUTH',
      'TIRUMANGALAM / WEST',
      'KALLIKUDI',
      'TIRUMANGALAM/ EAST',
      'T.KALLUPATTY / RURAL',
      'TIRUMANGALAM / NORTH',
      'KAPPALUR',
      'THIRUPARAN-GUNDRAM',
      'T.KALLUPATTY / TOWN',
      'TIRUMANGALAM / TOWN',
      'PERIYUR',
      'SENGULAM',
      'M.KALLUPATTY',
      'SINDUPATTY',
      'VALANDUR',
      'CHEKKANURANI',
      'ALWARNAGAR',
      'USILAMPATTY / RURAL /WEST',
      'ELUMALAI /TOWN',
      'CHINNAKATTALAI',
      'USILAMPATTY / RURAL /NORTH',
      'USILAMPATTY / RURAL /SOUTH',
      'T.RAMANATHA-PURAM',
      'ELUMALAI /RURAL',
      'UTHAPPA-NAICKANUR',
      'USILAMAPATTY / TOWN',
    ],
    edc: 'KARUR EDC',
  },
  {
    sections: [
      'ARASARADI /NORTH',
      'ARASARADI/ WEST',
      'VILANGUDI',
      'PP CHAVADI',
      'GNANVOLIPURAM',
      'ARASARADI/ SOUTH',
      'PALANGANATHAM ',
      'SS COLONY',
      'PASUMALAI',
      'TOWN/  TIRUPURAGUNRAM ',
      'TVS NAGAR',
      'JEEVANAGAR',
      'JAIHINDPURAM',
      'AVANIYAPURAM',
      'VILLAPURAM',
      'TAMUKKAM',
      'RACE COURSE',
      'SELLUR',
      'TAGORE NAGAR',
      'CHOKKIKULAM',
      'THIRUPPALAI',
      'ATHIKULAM',
      'ANAIYUR',
      'ANNA NAGAR',
      'K PUDUR',
      'K.K. NAGAR',
      'MELAMADAI',
      'SUBRAMINYAPURAM',
      'YANAIKKAL',
      'TOWN HALL',
      'TAMIL SANGAM',
      'ARAPPALAYAM',
      'KOVIL',
      'MAHAL',
      'MAHALIPATTY',
      'JANSI',
      'ARASAMARAM',
      'THEPPAM',
      'MUNISALAI',
      'KELA VASAL',
      'CHINATHAMANI',
      'ANUPPANADU',
      'THIRUKUVALAI',
      'NAGORE',
      'KANGALANCHEARY',
      'KARIYAPATTINAM',
      'KILVELLORE',
      'TOWN/NAGAPATTINAM',
      'SIKKAL',
      'THIRUMARUGAL',
      'VEDARANYAM',
      'VELIPALAYAM',
      'VOIMEDU',
      'VIZHANTHAMAVADI',
      'VELANKANNI',
      'THIRUPOONDI',
      'MAYILADUTHURAI/TOWN/ WEST',
      'THIRUVILANDUR',
      'MANGANALLUR',
      'KILIYANOOR',
      'MOOVALUR',
      'SS.NALLUR',
      'MAKKRIMANGALAM',
      'KOMAL',
      'MAYILADUTHURAI/TOWN/ EAST',
      'NIDUR',
      'PALAYUR',
      'SANKARANPANTHAL',
      'KADALANGUDY',
      'KUTHALAM',
      'MAYILADUTHURAI /RURAL',
      'SIRKALI /TOWN',
      'KOLLIDAM',
      'ARASUR',
      'VAITHEESWARANKOIL',
      'THIRUVENKADU',
      'POOMBUHAR',
      'THIRUKADAIYUR',
      'AKUR',
      'KALI',
      'KIDARANKONDAN',
      'MANALMEDU /RURAL',
      'SEMBANARKOIL',
      'SIRKALI /RURAL',
      'SIRKALI /WEST',
      'THARANGAMBADI',
      'MADANAM',
      'MANALMEDU /TOWN',
    ],
    edc: 'MADURAI (METRO)',
  },
  {
    sections: [
      'THELUR',
      'JAYANKONDAM NORTH',
      'ANDIMADAM/SOUTH',
      'SENDURAI /RURAL',
      'ARIYALUR /RURAL',
      'ARIYALUR/URBAN',
      'MATHUR',
      'MEENSURITY',
      'SENDURAI/RURAL II',
      'T.PALUR',
      'UDAYARPALAYAM',
      'ARIYALUR/NORTH',
      'KEELAPALUR',
      'ELAKURICHY',
      'PAPPAKUDY',
      'PERIYATHUKURICHY',
      'SUTHAMALLI',
      'THIRUMALAPADY',
      'THIRUMANUR',
      'ANDIMADAM/ NORTH',
      'JAYANKONDAM/ SOUTH',
      'PERAMBALUR/TOWN',
      'PERAMBALUR/ NORTH',
      'CHETTIKULAM /  NORTH',
      'VEPPANTHATTAI',
      'VEPPUR',
      'CHINNARDAM',
      'KAIKALATHUR',
      'KRISHNAPURAM/  NORTH',
      'KRISHNAPURAM/  RURAL',
      'KURUMBALUR',
      'ARUMBAVOOR',
      'ESANAI',
      'SIRUVACHUR',
      'V.KALATHUR',
      'VALIKANDAPURAM',
      'AMMAPALAYAM',
      'PADALUR',
      'KOLAKKANATHAM',
      'NAKKASALEM',
      'LABBAIKUDIKADU',
      'PERAMBALUR/SOUTH',
      'POOLAMBADY',
      'CHETTIKULAM/ SOUTH',
      'THUNGAPURAM',
      'ARIYALUR /WEST',
    ],
    edc: 'PERAMBALUR EDC',
  },
  {
    sections: [
      'POOTHAMPATTY',
      'GUZILIAMPARAI / RURAL',
      'ERIODU I',
      'KOVILUR',
      'GUZILIAMPARAI / TOWN',
      'CHATRAPATTY',
      'VALLIPATTY',
      'SUKKAMPATTY',
      'PALAYAM',
      'KULATHUR',
      'ANGU NAGAR',
      'KAMACHIPURAM',
      'NGO COLONY',
      'THADICOMBU',
      'BUS STAND/DINDIGUL',
      'NAGAL NAGAR',
      'ROCKFORT',
      'NEHRUJI NAGAR',
      'BEGAMPUR',
      'PANDIAYN NAGAR',
      'PONNAGARAM',
      'GANDHIGRAM',
      'GOPALPATTY',
      'NATHAM /RURAL / WEST',
      'EAST/ DINDIGUL',
      'BALAKRISHNAPURAM',
      'SILUVATHUR',
      'SIRUKUDY',
      'TAMARAIPADY',
      'VADAMADURAI/TOWN',
      'RURAL/VADAMADURAI',
      'SENDURAI',
      'N.PANJAMPATTY',
      'SEMPATTY',
      'AYYALUR',
      'V.KURUMBAPATTY',
      'CHINNALAPATTY',
      'NATHAM /TOWN',
      'KODAIKANAL/RURAL',
      'PANNAIKADU',
      'KODAIKANAL / WEST',
      'KODAIKANAL / EAST',
      'THANDIKUDY',
      'AYYAMPALAYAM',
      'AMMAYA-NAICKANOOR',
      'BATLAGUNDU/WEST',
      'VIRUVEDU',
      'SITHAYANKOTTAI',
      'RAMARAJAPURAM',
      'NILAKOTTAI / RURAL',
      'BATLAGUNDU/EAST',
      'NILAKOTTAI/ TOWN',
      'BATLAGUNDU/ TOWN',
      'KANNIVADY',
      'KALLIMANDAYAM/ EAST',
      'AMBILIKAI',
      'K.KEERANUR',
      'KALLIMANDAYAM/ NORTH',
      'PANNAIPATTY',
      'VIRUPATCHI',
      'MOOLACHATRAM',
      'MARKAMPATTY',
      'KETHAIYURUMBU',
      'EDAYAKOTTAI',
      'KALLIMANDAYAM/ WEST',
      'ODDANCHATRAM/ TOWN',
      'VELLANAMPATTY',
      'SENANKOTTAI',
      'KALWARPATTY',
      'VEDASANDUR / TOWN',
      'NEIKKARAPATTY',
      'AYAKUDY',
      'SAMINATHAPURAM',
      'PAPPAMPATTY',
      'PALANI /RURAL / SOUTH',
      'KEERANUR',
      'CHATRAPTTY/WEST',
      'CHATRAPTTY/EAST',
      'C.K.PUTHUR',
      'THOPPAMPATTY',
      'PALANI /NORTH',
      'ADIVARAM',
      'PALANI /TOWN',
    ],
    edc: 'Dindigul',
  },
  {
    sections: [
      'NAGERCOIL /TOWN I',
      'NAGERCOIL /TOWN III',
      'NAGERCOIL /TOWN V',
      'ETHAMOZHY',
      'RAJAKKAMANGALAM',
      'NAGERCOIL /TOWN II',
      'NAGERCOIL /TOWN IV',
      'NAGERCOIL /TOWN VI',
      'PARVATHYPURAM',
      'KOTTARAM',
      'MYLAUDY',
      'KANYAKUMARI',
      'KEEZHAKRISHNANPUTHOOR',
      'SUCHINDRAM',
      'THENGAMPUDUR',
      'ARALVAIMOZHY',
      'AZHAGIAPANDIPURAM',
      'BOOTHAPANDY',
      'THOVALAI',
      'THUCKULAY - 1',
      'THUCKULAY /TOWN II',
      'THICKANAMCODE',
      'VELLICHANDHAI',
      'COLACHEL ',
      'COLACHEL II',
      'ERANIEL',
      'MANAVALAKURICHY',
      'PARASERY',
      'KULASEKARAM RURAL',
      'KULASEKARAM URBAN',
      'THIRUVATTAR',
      'KUZHITHURAI',
      'MARTHANDAM',
      'ARUMANAI',
      'KALIYAKKAVILAI',
      'KALIYAL',
      'KANNUMAMOODU',
      'PUTHENCHANDAI',
      'PUTHUKADAI',
      'KARUNGAL',
      'KILLIYOOR',
      'KOLLENCODE',
      'ERAVIPUTHOORKADAI',
      'NAMBOLY',
      'PALLIYADI',
      'SOORIYACODE',
    ],
    edc: 'Kanyakumari',
  },
  {
    sections: [
      'PANAIYUR',
      'ALAGARKOIL',
      'VIRAGANOOR',
      'PERUNKUDI',
      'NARASINGAMPATTY',
      'URANGANPATTY',
      'MELUR /RURAL / SOUTH',
      'KOTTAMPATTY',
      'MELUR /RURA/EAST',
      'KADACHANENDAL',
      'KARUPPAYURANI',
      'OTHAKKADAI',
      'KARUNGALAKUDY',
      'KEELAIYUR',
      'MELUR /RURAL / WEST',
      'MELUR /TOWN',
      'KEELAMADHUR',
      'SHOLAVANDAN / RURAL',
      'AYYANKOTTAI',
      'VICKIRA-MANGALAM',
      'PALAMEDU',
      'ALANGANALLUR',
      'KOODALNAGAR',
      'SAMAYANALLUR / RURAL',
      'VADIPATTY',
      'SHOLAVANDAN / TOWN',
      'INDL.ESTATE/ KAPPALUR',
      'SAPTUR',
      'TIRUMANGALAM / SOUTH',
      'TIRUMANGALAM / WEST',
      'KALLIKUDI',
      'TIRUMANGALAM/ EAST',
      'T.KALLUPATTY / RURAL',
      'TIRUMANGALAM / NORTH',
      'KAPPALUR',
      'THIRUPARAN-GUNDRAM',
      'T.KALLUPATTY / TOWN',
      'TIRUMANGALAM / TOWN',
      'PERIYUR',
      'SENGULAM',
      'M.KALLUPATTY',
      'SINDUPATTY',
      'VALANDUR',
      'CHEKKANURANI',
      'ALWARNAGAR',
      'USILAMPATTY / RURAL /WEST',
      'ELUMALAI /TOWN',
      'CHINNAKATTALAI',
      'USILAMPATTY / RURAL /NORTH',
      'USILAMPATTY / RURAL /SOUTH',
      'T.RAMANATHA-PURAM',
      'ELUMALAI /RURAL',
      'UTHAPPA-NAICKANUR',
      'USILAMAPATTY / TOWN',
    ],
    edc: 'Madurai',
  },
  {
    sections: [
      'THIRUKUVALAI',
      'NAGORE',
      'KANGALANCHEARY',
      'KARIYAPATTINAM',
      'KILVELLORE',
      'TOWN/NAGAPATTINAM',
      'SIKKAL',
      'THIRUMARUGAL',
      'VEDARANYAM',
      'VELIPALAYAM',
      'VOIMEDU',
      'VIZHANTHAMAVADI',
      'VELANKANNI',
      'THIRUPOONDI',
      'MAYILADUTHURAI/TOWN/ WEST',
      'THIRUVILANDUR',
      'MANGANALLUR',
      'KILIYANOOR',
      'MOOVALUR',
      'SS.NALLUR',
      'MAKKRIMANGALAM',
      'KOMAL',
      'MAYILADUTHURAI/TOWN/ EAST',
      'NIDUR',
      'PALAYUR',
      'SANKARANPANTHAL',
      'KADALANGUDY',
      'KUTHALAM',
      'MAYILADUTHURAI /RURAL',
      'SIRKALI /TOWN',
      'KOLLIDAM',
      'ARASUR',
      'VAITHEESWARANKOIL',
      'THIRUVENKADU',
      'POOMBUHAR',
      'THIRUKADAIYUR',
      'AKUR',
      'KALI',
      'KIDARANKONDAN',
      'MANALMEDU /RURAL',
      'SEMBANARKOIL',
      'SIRKALI /RURAL',
      'SIRKALI /WEST',
      'THARANGAMBADI',
      'MADANAM',
      'MANALMEDU /TOWN',
    ],
    edc: 'Nagai',
  },
  {
    edc: 'Tiruchirappalli',
    sections: [
      'KALLAKUDI/  RURAL',
      'VELLANUR',
      'LALGUDI/ RURAL1',
      'LALGUDI/ RURAL2',
      'PULLAMPADY',
      'VALADI',
      'LALGUDI/URBAN',
      'KATTUPUTHUR',
      'TPET /SOUTH',
      'ELURPATTY',
      'THOTTIYAM',
      'THUMBALAM',
      'TPET /RURAL',
      'TPET /TOWN',
      'JAMBUNATHAPURAM',
      'KOLAKKUDY',
      'METTUPALAYAM',
      'MUSIRI/NORTH',
      'T.PET/RURAL/WEST',
      'THANDALAI-PUTHUR',
      'AYYAMPALAYAM',
      'MUSIRI /TOWN',
      'VENKATACHALPURAM',
      'PULIVALAM',
      'PUTHANAMPATTY',
      'KOPPAMPATTY',
      'ERAKUDY',
      'KANNANOOR',
      'KOTTAPALAYAM',
      'MURUNGATTUPATTY/ TOWN',
      'SENGATTUPATTY',
      'THURAIYUR/NORTH',
      'THURAIYUR/SOUTH',
      'THURAIYUR/WEST',
      'THURAIYUR/EAST',
      'V.C.PALAYAM',
      'SIKKATHAMBUR',
      'UPPILIAPURAM',
      'THURAIYUR/URBAN',
      'THILLAINAGAR',
      'CHINTHAMANI',
      'JUNCTION',
      'WORAIYUR',
      'MAINGUARDGATE',
      'SENTHANEERPURAM',
      'SRINIVASANAGAR',
      'THENNUR',
      'ROCKFORT',
      'PALAKKARAI',
      'CONTONEMENT',
      'PONNAGAR',
      'MAHALAKSHMINAGAR',
      'GANDHIMARKET',
      'SUBRAMANIYAPURAM',
      'ARIYAMANGALAM',
      'CRAWFORD',
      'K.K.NAGAR',
      'KATTUR',
      'SEMPATTU',
      'THIRU-VERUMBUR',
      'MANNARPURAM',
      'KALKANDARKOTTAI',
      'THUVAKUDY/SOUTH',
      'SOMARASAMPETTAI',
      'NAVALPATTU',
      'THUVAKUDY/WEST',
      'THIRUPPARAITHURAI',
      'KAILASAPURAM',
      'TRICHY/RURAL',
      'MANIKANDAM',
      'THUVAKUDY/EAST',
      'MANAPPARAI/TOWN',
      'MANAPPARAI/NORTH',
      'THUVARANKURICHY/ SOUTH',
      'MARUNGAPURI',
      'MANAPPARAI/RURAL',
      'MANAPPARAI/WEST',
      'VAIYAMPATTY/SOUTH',
      'THUVARANKURICHY/ NORTH',
      'NADUPATTY',
      'PALAKURICHI',
      'PUTHTHANATHAM',
      'MANAPPARAI/SOUTH',
      'VAIYAMPATTY/NORTH',
      'MANAPARAI/EAST',
      'METTUPATTI',
      'SRIRANGAM',
      'T.V.KOIL',
      'AMMANMANDABAM',
      'MANNACHANALLUR/ TOWN',
      'BITCHANDARKOIL',
      'MANNACHANALLUR/ WEST',
      'SAMAYAPURAM',
      'SIRUGAMBUR',
      'SIRUGANUR',
      'MANNACHANALLUR/ EAST',
    ],
  },
  {
    edc: 'Thanjavur',
    sections: [
      'THIRUKKANURPATTY',
      'THIRUVAIYARU /TOWN',
      'MINNAGAR',
      'KALLAPERAMBUR',
      'MELATTUR',
      'KURUNGULAM',
      'MARUNGULAM',
      'NADUCAUVERY',
      'VEERAMARASAN-PETTAI',
      'NEELAGIRI',
      'THANJAVUR / RURAL/NORTH',
      'THIRUKKATTUPALLI / RURAL',
      'THIRUKKATTUPALLI / TOWN',
      'THIRUVAIYARU /RURAL',
      'VALLAM',
      'SENGIPATTY',
      'THANJAVUR/RURAL/ WEST',
      'KABISTHALAM',
      'RURAL/PAPANASAM',
      'GANAPATHY-AGRAGARAM',
      'AYYAMPETTAI /RURAL',
      'AYYAMPETTAI /TOWN',
      'PAPANASAM/TOWN',
      'THIRUKARUKAVUR',
      'KUMBAKONAM / NORTH',
      'KUMBAKONAM /WEST',
      'KUMBAKONAM/EAST',
      'KUMBAKONAM /SS / URBAN',
      'KUMBAKONAM / SOUTH',
      'V.KOLLAIKADU',
      'ALATHUR',
      'NORTH/MADUKKUR',
      'THAMARANKOTTAI',
      'POOKKLLAI',
      'PERUMAGALUR',
      'PATUKOTTAI /RURAL/EAST',
      'MADUKKUR/RURAL',
      'OTTANGADU',
      'MADUKKUR/TOWN',
      'PALLATHUR',
      'THIRUCHITRAMBALAM',
      'ADHIRAMAPATTINAM',
      'KURUVIKARAMBAI',
      'NADIYUM',
      'PATUKOTTAI /RURAL / WEST',
      'TOWN/PERAVURANI',
      'RURAL/PERAVURANI',
      'PERUMALKOIL',
      'THUVARANKURUCHI',
      'PATUKOTTAI /TOWN I',
      'PATUKOTTAI /TOWN II',
      'ORATHANAD/EAST',
      'PINNAIYUR',
      'MELAULUR',
      'URANTHARAYAN KUDIKADU',
      'THIRUVONAM',
      'SOORAKKOTTAI',
      'AMMAPATTAI/SOUTH',
      'ORATHANADU /NORTH',
      'MARIAMMAN KOIL',
      'ORATHANDU /SOUTH',
      'PAPPANADU',
      'SALIYAMANGALAM',
      'URANIPURAM',
      'AMMAPETTAI',
      'POIYUNDAR KOTTAI',
      'OKKANADU/KEELAIYUR',
      'PANAYAKKOTTAI',
      'VADASERI',
      'KANNUKUDI/WEST',
      'ESWARI NAGAR',
      'COURT ROAD',
      'ARULANANDHA NAGAR',
      'PALACE',
      'MANAMBUCHAVADY',
      'KARANTHAI ',
      'CHETTIMANDABAM',
      'THIPPIRAJAPURAM',
      'THIRUNEELAGUDI',
      'SEMMANGUDI',
      'KONULAMBIYAM',
      'THIRUPURAMBIYAM',
      'KADIRAMANGALAM',
      'PATTESWARAM',
      'ADUTHURAI',
      'CHOLAPURAM',
      'NATCHIYARKOIL',
      'PANDANALLUR',
      'THIRUPANNANDAL',
      'THIRUVIDAIMARUTHUR',
      'KURURICHI',
      'THIRUNAGEESWARAM',
      'SWAMIMALAI',
    ],
  },
  {
    edc: 'Krishnagiri',
    sections: [
      'VEPPANAPALLY',
      'KRISHNAGIRI/OLDPET',
      'ALAPATTY',
      'SHOOLAGIRI-II/ RURAL',
      'KAVERIPATTINAM / RURAL II/ NORTH',
      'M.C.PALLY',
      'SHOOLAGIRI-I/TOWN',
      'KRISHNAGIRI /TOWN/ RURAL-I',
      'KAVERIPATTINAM /TOWN',
      'KAVERIPATTINAM /RURAL I/ SOUTH',
      'KRISHNAGIRI /RURAL-II',
      'KRISHNAGIRI /RURAL III/INDL. ESTATE',
      'AVATHANAPATTY',
      'KUTTIGOUNDANOOR',
      'CHINNAR',
      'GURUBARAPALLY',
      'HOSUR /WEST',
      'HOSUR/SIPCOT-I',
      'DENKANIKOTTAI/ SOUTH',
      'HOSUR /SOUTH',
      'HOSUR/SIPCOT-II',
      'HOSUR /RURAL',
      'KELAMANGALAM',
      'BERIGAI',
      'DENKANIKOTTAI/ NORTH',
      'MATHIGIRI',
      'HOSUR/CENTRAL',
      'HOSUR/SIDCO',
      'THALLY',
      'BAGALUR/TOWN',
      'UDDANAPALLY',
      'BELAGONDAPALLY',
      'ANDIVADI',
      'ARASANATTY',
      'BAGALUR / RURAL',
      'ANCHETTY',
      'BATHALAPALLY',
      'HOSUR / NORTH',
      'MADHAGONDAPALLY',
      'EAST/KELAMANGALAM',
      'JAWALAGIRI',
      'ATHIMUGAM',
      'ELECOT/HOSUR',
      'ARASAMPATTY',
      'MATHUR-II',
      'UTHANGARAI-I',
      'KALLAVI',
      'SANTHUR',
      'VARATANAPALLY',
      'JAGADEVI',
      'UTHANGARAI-II',
      'NAGARASAMPATTY',
      'BARGUR-II/RURAL',
      'BARGUR/ SIDCO',
      'KARAPATTU',
      'SAMALPATTY',
      'POCHAMPALLY',
      'MATHUR-I',
      'BARGUR-I/TOWN',
      'ELATHAGIRI',
      'PANNANTHUR',
    ],
  },
  {
    sections: [
      'THIRUVAPPUR',
      'ADHANAKOTTAI /SS',
      'PUNALKULAM /SS',
      'PUDUKKOTTAI /RURAL/EAST',
      'ILLUPPUR /TOWN',
      'GANDARVAKOTTAI /SOUTH',
      'GANDARVAKOTTAI /NORTH',
      'ANNAVASAL',
      'ANNAPANNAI',
      'ANDAKULAM',
      'PERUNGALUR',
      'GANDARVAKOTTAI EAST',
      'ILLUPPUR /SS',
      'SIPCOT',
      'PUDUKKOTTAI/TOWN/NORTH',
      'PUDUKKOTTAI/TOWN/CENTRAL',
      'PUDUKKOTTAI/TOWN/EAST',
      'PUDUKKOTTAI/TOWN/WEST',
      'MATHUR /RURAL/ SOUTH',
      'T.NALLUR',
      'KEERANUR /SOUTH',
      'VIRALIMALAI /NORTH',
      'KUNNANDARAKOIL',
      'VADUGAPATTY',
      'VIRALIMALAI /SS',
      'MELAPACHAKUDI',
      'KUMARAMANGALAM',
      'KEERANUR /NORTH',
      'KEERANUR/EAST',
      'VIRALIMALAI /SOUTH',
      'THENIPATTY',
      'KULIPIRAI',
      'ARIMALAM',
      'R.C.PURAM',
      'KARAIYUR',
      'PONNAMARAVATHI/URBAN',
      'NACHANDUPATTY',
      'THIRUMAYAM',
      'PONNAMARAVATHI/RURAL',
      'ARANTHANGI /URBAN/ WEST',
      'AVUDAIYARKOIL',
      'KODIKULAM',
      'KEERAMANGALAM/ SOUTH',
      'MANAMELGUDI',
      'KARUR',
      'AVANATHANKOTTAI',
      'SILATTUR',
      'SUBRAMANIYAPURAM',
      'KEERAMANGALAM/NORTH',
      'NAGUDI /SS',
      'ARANTHANGI /TOWN',
      'WEST/ALANGUDY',
      'VETANVEDUTHY',
      'KARUKKAKURICHI',
      'ALANGUDY/NORTH',
      'VADAKADU',
      'MALAIYUR',
      'KARAMBAKUDY',
      'REGUNATHAPURAM',
      'SEMPATTIVIDUTHY',
      'VALLATHIRAKOTTAI',
      'KOTHAMANGALAM',
      'LN PURAM',
      'PACHIKOTTAI',
      'PUDUPATTY',
      'THIRUVARANGULAM',
      'TOWN/ ALANGUDY',
    ],
    edc: 'PUDUKOTTAI',
  },
  {
    sections: [
      'PARAMAKUDI /RURAL',
      'KAMUTHI/RURAL/NORTH',
      'NAINARKOIL',
      'CHATRAKUDI',
      'ABIRAMAM',
      'KADALADI',
      'MUDUKULATHUR',
      'SAYALKUDI',
      'SIKKAL',
      'KAMUTHI/RURAL/SOUTH',
      'PARTHIBANUR',
      'PERUNALI',
      'PARAMAKUDI/TOWN/N',
      'PARAMAKUDI/TOWN/S',
      'KAMUTHI /TOWN',
      'UCHIPULI',
      'MANDAPAM',
      'T.U.MANGAI',
      'THIRUVADANAI /SS',
      'THIRUVADANAI',
      'REGUNATHAPURAM',
      'PANAIKULAM',
      'RAMNAD /RURAL',
      'R.S.MANGALAM',
      'THONDI',
      'DEVIPATTINAM',
      'RAMESWARAM',
      'KILAKARAI',
      'RAMNAD/TOWN/NORTH',
      'RAMNAD/TOWN/SOUTH',
    ],
    edc: 'RAMANAD',
  },
  {
    sections: [
      'KARAIKUDI/RURAL',
      'KALLAL',
      'KANDRAMANICKAM',
      'DEVAKOTTAI/RURAL/NORTH',
      'DEVAKOTTAI/RURAL/SOUTH',
      'KARAIKUDI/TOWN/NORTH',
      'PUDUVAYAL',
      'KANADUKATHAN',
      'DEVAKOTTAI/TOWN',
      'KARAIKUDI/TOWN/SOUTH',
      'ILAYANGUDI / RURAL',
      'POOVANTHY',
      'RAJAGAMBEERAM',
      'KEELADY',
      'THIRUPPACHETHY',
      'SALAIGRAMAM',
      'THIRUPPUVANAM/EAST',
      'MANAMADURAI/RURAL',
      'MANAMADURAI/TOWN',
      'ILAYANGUDI/TOWN',
      'THIRUPPUVANAM/WEST',
      'SIVAGANGA / RURAL',
      'MALAMPATTY',
      'NATTARASANKOTTAI',
      'MARAVAMANGALAM',
      'KALAYARKOIL',
      'MADAGUPATTY',
      'SIVAGANGA / TOWN',
      'S.PUDUR',
      'PULITHIPATTY',
      'S.V.MANGALAM',
      'A.THEKKUR',
      'THIRUPPATHUR/RURAL/SOUTH',
      'THIRUPPATHUR/RURAL/NORTH',
      'THIRUPPATHUR/TOWN',
      'SINGAMPUNERI/TOWN',
      'THIRUKKANURPATTY',
      'THIRUVAIYARU /TOWN',
      'MINNAGAR',
      'KALLAPERAMBUR',
      'MELATTUR',
      'KURUNGULAM',
      'MARUNGULAM',
      'NADUCAUVERY',
      'VEERAMARASAN-PETTAI',
      'NEELAGIRI',
      'THANJAVUR / RURAL/NORTH',
      'THIRUKKATTUPALLI / RURAL',
      'THIRUKKATTUPALLI / TOWN',
      'THIRUVAIYARU /RURAL',
      'VALLAM',
      'SENGIPATTY',
      'THANJAVUR/RURAL/ WEST',
      'KABISTHALAM',
      'RURAL/PAPANASAM',
      'GANAPATHY-AGRAGARAM',
      'AYYAMPETTAI /RURAL',
      'AYYAMPETTAI /TOWN',
      'PAPANASAM/TOWN',
      'THIRUKARUKAVUR',
      'KUMBAKONAM / NORTH',
      'KUMBAKONAM /WEST',
      'KUMBAKONAM/EAST',
      'KUMBAKONAM /SS / URBAN',
      'KUMBAKONAM / SOUTH',
      'V.KOLLAIKADU',
      'ALATHUR',
      'NORTH/MADUKKUR',
      'THAMARANKOTTAI',
      'POOKKLLAI',
      'PERUMAGALUR',
      'PATUKOTTAI /RURAL/EAST',
      'MADUKKUR/RURAL',
      'OTTANGADU',
      'MADUKKUR/TOWN',
      'PALLATHUR',
      'THIRUCHITRAMBALAM',
      'ADHIRAMAPATTINAM',
      'KURUVIKARAMBAI',
      'NADIYUM',
      'PATUKOTTAI /RURAL / WEST',
      'TOWN/PERAVURANI',
      'RURAL/PERAVURANI',
      'PERUMALKOIL',
      'THUVARANKURUCHI',
      'PATUKOTTAI /TOWN I',
      'PATUKOTTAI /TOWN II',
      'ORATHANAD/EAST',
      'PINNAIYUR',
      'MELAULUR',
      'URANTHARAYAN KUDIKADU',
      'THIRUVONAM',
      'SOORAKKOTTAI',
      'AMMAPATTAI/SOUTH',
      'ORATHANADU /NORTH',
      'MARIAMMAN KOIL',
      'ORATHANDU /SOUTH',
      'PAPPANADU',
      'SALIYAMANGALAM',
      'URANIPURAM',
      'AMMAPETTAI',
      'POIYUNDAR KOTTAI',
      'OKKANADU/KEELAIYUR',
      'PANAYAKKOTTAI',
      'VADASERI',
      'KANNUKUDI/WEST',
      'ESWARI NAGAR',
      'COURT ROAD',
      'ARULANANDHA NAGAR',
      'PALACE',
      'MANAMBUCHAVADY',
      'KARANTHAI ',
      'CHETTIMANDABAM',
      'THIPPIRAJAPURAM',
      'THIRUNEELAGUDI',
      'SEMMANGUDI',
      'KONULAMBIYAM',
      'THIRUPURAMBIYAM',
      'KADIRAMANGALAM',
      'PATTESWARAM',
      'ADUTHURAI',
      'CHOLAPURAM',
      'NATCHIYARKOIL',
      'PANDANALLUR',
      'THIRUPANNANDAL',
      'THIRUVIDAIMARUTHUR',
      'KURURICHI',
      'THIRUNAGEESWARAM',
      'SWAMIMALAI',
    ],
    edc: 'SIVAGANGAI',
  },
  {
    sections: [
      'THEVARAM II',
      'THENI /WEST',
      'VEERAPANDI',
      'THEVARAM I',
      'THENI /SOUTH',
      'THEN/EAST',
      'GANDAMANUR',
      'RASINGAPURAM',
      'BODI /WEST',
      'P.C.PATTY',
      'THENI /NORTH',
      'BODI /TOWN',
      'BOD/EAST',
      'THENI /TOWN',
      'VAIGAIDAM',
      'AUNDIPATTY /WEST',
      'JAYAMANGALAM',
      'MADURAPURI',
      'AUNDIPATTY /SOUTH (Rajathani)',
      'ANDIPATTY/EAST',
      'GADAMALAIGUNDU',
      'DEVADANAPATTY',
      'PERIYAKULAM/EAST',
      'AUNDIPATTY /NORTH',
      'KEELAVADAKARAI',
      'MYLADUMPARAI',
      'AUNDIPATTY /TOWN',
      'PERIYAKULAM /TOWN',
      'THAMARAIKULAM',
      'CUMBUM /RURAL',
      'ERASAKKANAIKANUR',
      'KAMAYAGOUNDANPATTY',
      'MARKKAYANKOTTAI',
      'KOMBAI',
      'SURULIPATTY',
      'North/Cumbum',
      'KAMATCHIPURAM',
      'ODAIPATTY',
      'GUDALUR',
      'CHINNAMANUR /RURAL',
      'CUMBUM /TOWN',
      'UTHAMAPALAYAM',
      'CHINNAMANUR /TOWN',
    ],
    edc: 'THENI EDC',
  },
  {
    sections: [
      'THIRUVARUR /TOWN',
      'KORADACHERY',
      'KODAVASAL',
      'NANNILAM',
      'PERALAM',
      'VALANGAIMAN',
      'THIRUVARUR /SOUTH',
      'THIRUVARUR/NORTH',
      'ADIYAKKAMANGALAM',
      'KATCHANAM',
      'THIRUVARUR /WEST',
      'SRIVANJIYAM/ THIRUVANJIYAM',
      'POONTHOTTAM',
      'ADAMBAR',
      'VELANGUDY',
      'ALANGUDI',
      'MANNARGUDI /TOWN I',
      'MANNARGUDI/TOWN II',
      'KOOTHANALLUR ',
      'NEDAMANGALAM',
      'THIRUTHURAIPOONDI / TOWN',
      'MUTHUPET',
      'MANNARGUDI/WEST',
      'MANNARGUDI/NORTH',
      'PERAIYUR',
      'EAST/MANNARGUDI',
      'VADAPATHIMANGALAM',
      'VADUVUR',
      'KOILVENNI',
      'EDAMALIYUR',
      'THIRUTHURAIPOONDI / RURAL',
      'PALLANKOIL (Thiruthuraipundi)',
      'KOTTUR',
      'THIRUMAKKOTAI',
      'ULLIKKOTTAI',
      'PARAVAKKOTAI',
    ],
    edc: 'THIRUVARUR EDC',
  },
  {
    sections: [
      'PERUMALPURAM',
      'MELAPALAYAM I',
      'THIRUNELVELI /TOWN',
      'VANNARPET',
      'THIRUNELVELI / JUNCTION',
      'PETTAI',
      'SAMATHANAPURAM',
      'PALAYAPETTAI',
      'THATCHANALLUR',
      'VM CHATRAM',
      'REDDIYARPATTI',
      'SANTHINAGAR',
      'MAHARAJANAGAR',
      'MELAPALAYAM II',
      'THIRUNELVELI /RURAL /NORTH',
      'ALANGULAM /SS',
      'VENKATESAPURAM',
      'ALANGULAM RURAL',
      'MUNANCHIPATTI',
      'ALANGULAM TOWN',
      'DEVARKULAM',
      'MANUR',
      'NANGUNERI RURAL',
      'UTHUMALAI',
      'VANNIKONENDHAL',
      'THALAIYUTHU TOWN-II',
      'ADAIKALAPATTINAM',
      'MOOLAIKARAIPATTI',
      'GANGAIKONDAN',
      'SUTHAMALLI',
      'NANGUNERI TOWN',
      'THALAIYUTHU TOWN-I',
      'VEERAKERALAMPUDUR',
      'AVUDAIANUR',
      'PAVOORCHATRAM',
      'VADAKARAI',
      'SHENCOTTAH /SS/R',
      'TENKASI RURAL',
      'KEELAPAVUR',
      'SUSRANDAI',
      'AYAKUDI',
      'TENKASI TOWN-II',
      'SURANDAI /SS',
      'TENKASI TOWN-I',
      'SUNDARAPANDIAPURAM',
      'COURTRALLAM',
      'SHENCOTTAI/ TOWN',
      'TENKASI /SS',
      'KALINGAPPATTI',
      'PAZHAMCOTTAI',
      'PERUMALPATTI',
      'KARIVALAMVANTHANALLUR ',
      'SANKARANKOIL RURAL',
      'KURUKALPATTI',
      'KURUVIKULAM',
      'SANKARANKOIL TOWN-I',
      'THIRUVENKADAM',
      'SANKARANKOIL TOWN-II',
      'PAPANASAM',
      'MANJOLAI',
      'AMBASAMUDRAM RURAL',
      'KADAYAM',
      'PAPPAKUDI',
      'MUKKUDAL',
      'ALWARKURICHI',
      'GOPALASAMUDRAM',
      'V.K.PURAM /SS',
      'AMBASAMUDRAM TOWN ',
      'VIKRAMASINGAPURAM TOWN',
      'CHERANMAHADEVI',
      'VEERAVANALLUR',
      'KALLIDAIKURICHI',
      'KOTTAIKARUNGULAM',
      'RADHAPURAM',
      'VADAKANKULAM',
      'LEVENCHIPURAM',
      'THISAYANVILAI RURAL',
      'PERUNGUDI',
      'KOODANKULAM',
      'KALLIKULAM',
      'KALAKAD RURAL',
      'ITTAMOZHI',
      'VALLIYUR',
      'PANAGUDI',
      'THISAYANVILLAI TOWN',
      'KALAKAD TOWN',
      'THIRUKANANGUDI',
      'ERVADI',
      'NAINARAGARAM',
      'SIVAGIRI',
      'SENTHAMARANM',
      'PULIANGUDI /SS',
      'MULLIKULAM',
      'KADAYANALLUR SOUTH',
      'VEERASIGAMANI',
      'KADAYANALLUR RURAL',
      'PULIANGUDI TOWN',
      'KADAYANALLUR T-I',
      'KADAYANALLUR T-II ',
      'PULIANGUDI RURAL/NORTH',
      'TOWN SIVAGIRI',
      'VASUDEVANALLUR',
      'RAYAGIRI',
      'KALLAKUDI/  RURAL',
      'VELLANUR',
      'LALGUDI/ RURAL1',
      'LALGUDI/ RURAL2',
      'PULLAMPADY',
      'VALADI',
      'LALGUDI/URBAN',
      'KATTUPUTHUR',
      'TPET /SOUTH',
      'ELURPATTY',
      'THOTTIYAM',
      'THUMBALAM',
      'TPET /RURAL',
      'TPET /TOWN',
      'JAMBUNATHAPURAM',
      'KOLAKKUDY',
      'METTUPALAYAM',
      'MUSIRI/NORTH',
      'T.PET/RURAL/WEST',
      'THANDALAI-PUTHUR',
      'AYYAMPALAYAM',
      'MUSIRI /TOWN',
      'VENKATACHALPURAM',
      'PULIVALAM',
      'PUTHANAMPATTY',
      'KOPPAMPATTY',
      'ERAKUDY',
      'KANNANOOR',
      'KOTTAPALAYAM',
      'MURUNGATTUPATTY/ TOWN',
      'SENGATTUPATTY',
      'THURAIYUR/NORTH',
      'THURAIYUR/SOUTH',
      'THURAIYUR/WEST',
      'THURAIYUR/EAST',
      'V.C.PALAYAM',
      'SIKKATHAMBUR',
      'UPPILIAPURAM',
      'THURAIYUR/URBAN',
      'THILLAINAGAR',
      'CHINTHAMANI',
      'JUNCTION',
      'WORAIYUR',
      'MAINGUARDGATE',
      'SENTHANEERPURAM',
      'SRINIVASANAGAR',
      'THENNUR',
      'ROCKFORT',
      'PALAKKARAI',
      'CONTONEMENT',
      'PONNAGAR',
      'MAHALAKSHMINAGAR',
      'GANDHIMARKET',
      'SUBRAMANIYAPURAM',
      'ARIYAMANGALAM',
      'CRAWFORD',
      'K.K.NAGAR',
      'KATTUR',
      'SEMPATTU',
      'THIRU-VERUMBUR',
      'MANNARPURAM',
      'KALKANDARKOTTAI',
      'THUVAKUDY/SOUTH',
      'SOMARASAMPETTAI',
      'NAVALPATTU',
      'THUVAKUDY/WEST',
      'THIRUPPARAITHURAI',
      'KAILASAPURAM',
      'MANIKANDAM',
      'THUVAKUDY/EAST',
      'MANAPPARAI/TOWN',
      'MANAPPARAI/NORTH',
      'THUVARANKURICHY/ SOUTH',
      'MARUNGAPURI',
      'MANAPPARAI/RURAL',
      'MANAPPARAI/WEST',
      'VAIYAMPATTY/SOUTH',
      'THUVARANKURICHY/ NORTH',
      'NADUPATTY',
      'PALAKURICHI',
      'PUTHTHANATHAM',
      'MANAPPARAI/SOUTH',
      'VAIYAMPATTY/NORTH',
      'MANAPARAI/EAST',
      'METTUPATTI',
      'SRIRANGAM',
      'T.V.KOIL',
      'AMMANMANDABAM',
      'MANNACHANALLUR/ TOWN',
      'BITCHANDARKOIL',
      'MANNACHANALLUR/ WEST',
      'SAMAYAPURAM',
      'SIRUGAMBUR',
      'SIRUGANUR',
      'MANNACHANALLUR/ EAST',
    ],
    edc: 'TIRUNELVELI',
  },
  {
    sections: [
      'PUDUKKOTTAI',
      'PUDUR',
      'VILATHIKULAM',
      'KULATHUR',
      'SRIVAIKUNDAM /RURAL',
      'PALAYAKAYAL',
      'VALLANADU',
      'SAWYERPURAM',
      'ERAL',
      'NAGALAPURAM',
      'SOORANGUDI',
      'SEIDUNGANALLUR',
      'SRIVAIKUNDAM /TOWN',
      'OTTAIPIDARAM',
      'MUTHIAHPURAM /SOUTH',
      'MUTHIAHPURAM /NORTH',
      'PUTHIAMPUTHUR',
      'PULIAMPATTY',
      'TUTICORIN /RURAL /WEST',
      'OTTANATHAM',
      'TUTICORIN /RURAL /NORTH',
      'TUTICORIN /URBAN /SOUTH',
      'TUTICORIN /URBAN /WEST',
      'TUTICORIN /URBAN /KEELUR',
      'TUTICORIN /URBAN /CENTRAL',
      'TUTICORIN /URBAN /EAST',
      'TUTICORIN /URBAN /NORTH',
      'EPPODUMVENDRAN',
      'KADALAIYUR',
      'NALATTINPUTHUR',
      'PASUVANTHANAI',
      'KADAMBUR',
      'ETTAYAPURAM',
      'KOVILPATTI /RURAL',
      'KAYATHAR',
      'KALUGUMALAI',
      'KOVILPATTI /TOWN /WEST',
      'KOVILPATTI /TOWN /SOUTH',
      'KOVILPATTI /TOWN /EAST',
      'KOVILPATTI /TOWN /NORTH',
      'PARAMANKURUCHY',
      'UDANGUDI /RURAL',
      'MEIGNANAPURAM',
      'NADUVAKURUCHI',
      'KAYAMOZHY',
      'PADUKKAPATHU',
      'SATTANKULAM /RURAL II',
      'KURUMBUR',
      'SATTANKULAM /RURAL I',
      'SATTANKULAM /URBAN',
      'UDANGUDI /URBAN',
      'NAZARETH',
      'KAYALPATTINAM',
      'THIRUCHENDUR',
      'ALWARTHIRUNAGARI',
      'ARUMUGANERI',
    ],
    edc: 'TUTICORIN EDC',
  },
  {
    sections: [
      'MALLANKINAR',
      'AMATHUR',
      'VIRUDHUNAGAR/RURAL/NORTH',
      'VIRUDHUNAGAR/RURAL/SOUTH',
      'THULUKKAPATTI',
      'SOOLAKARAI',
      'PANDIAN NAGAR',
      'VIRUDHUNAGAR/TOWN/EAST',
      'VIRUDHUNAGAR/TOWN/SOUTH',
      'VIRUDHUNAGAR/TOWN/WEST',
      'PANDALGUDI',
      'PARALACHI',
      'ARUPPUKOTTAI/RURAL',
      'VEERACHOLAN',
      'TIRUCHULI',
      'PALAYAMPATTI',
      'KARIAPATTI/SOUTH',
      'MUTHURAMALINGAPURAM',
      'ARUPPUKOTTAI/TOWN/NORTH',
      'ARUPPUKOTTAI/TOWN/WEST',
      'ARUPPUKOTTAI/TOWN/EAST',
      'KARIAPATTI/NORTH',
      'KOOMAPATYI',
      'SUNDRAPANDIAM',
      'WATRAP/EAST',
      'MALLIPUTHUR/SS',
      'MAMSAPURAM',
      'KUNNUR',
      'PADIKASUVAITHANPATTI',
      'WATRAP/WEST',
      'WATRAP/TOWN',
      'SRIVILLIPUTHUR/RURAL/NORTH',
      'SRIVILLIPUTHUR/RURAL/SOUTH',
      'VALAYAPATTI',
      'SRIVILLIPUTHUR/TOWN/WEST',
      'SRIVILLIPUTHUR/TOWN/EAST',
      'SRIVILLIPUTHUR/ MARKET BAZAAR',
      'KRISHNAPURAM',
      'DHALAVAIPURAM /RURAL',
      'THOTHIAPATTI',
      'SEITHUR',
      'KEELARAJAKULARAMAN',
      'KALLAMANAICKENPATTI',
      'AVARAMPATTI',
      'VADAKKUVENGANALLUR',
      'ALANGULAM/RURAL',
      'ASILAPURAM',
      'CHATRAPATTI',
      'DHALAVAIPURAM',
      'RAJAPALAYAM/TOWN/WEST',
      'RAJAPALAYAM/TOWN/NORTH',
      'RAJAPALAYAM/TOWN/EAST',
      'RAJAPALAYAM/TOWN/SOUTH',
      'SATTUR',
      'PARAIPATTI',
      'SITHURAJAPURAM',
      'VEMBAKOTTAI',
      'SENGAMALANACHIARPURAM',
      'ELAYIRAMPANNAI',
      'N.SUBBIAHPURAM',
      'SATTUR/RURAL/EAST',
      'SIVAKASI/SS',
      'SATTUR/TOWN',
      'TIRUTHANGAL',
      'SIVAKASI/TOWN/EAST',
      'SIVAKASI/TOWN/WEST',
      'SIVAKASI/TOWN/NORTH',
    ],
    edc: 'VIRUDHUNAGAR',
  },
  {
    sections: [
      'R.S.PURAM/NORTH',
      'RAMNAGAR',
      'TATABAD',
      'BAZAAR/WEST',
      'R.S.PURAM/SOUTH',
      'RACE COURSE',
      'RM PURAM',
      'TOWNHALL',
      'BAZAAR/EAST',
      'PAPANAIKENPALAYAM',
      'PULIAKULAM',
      'Selvapuram',
      'Lawley Road',
      'Ukkadam',
      'Ponnaiyarajapuram',
      'Krishnasamy nagar',
      'AVARAMPALAYAM',
      'PEELAMEDU /TOWN',
      'SAIBABA COLONY',
      'RATINAPURI',
      'GANAPATHY',
      'KAVUNDAMPALAYAM',
      'SANGANOOR',
      'VELANDIPALAYAM',
      'THANEERPANDAL',
      'Edayarpalayam',
      'Ganeshnagar',
      'Kavundampalayam/\nSouth',
      'Gandhimanagar',
      'PEELAMEDU /TOWN / EAST',
      'SINGANALLUR/EAST',
      'ONDIPUDUR',
      'CIVIL AERO',
      'SINGANALLUR /WEST',
      'SOWRIPALAYAM',
      'CHINNIAMPALAYAM / WEST',
      'VARADHARAJAPURAM',
      'Nehrunagar',
      'Kalapatty',
      'CHINNIAMPALAYAM/\nNORTH',
      'SULUR / NORTH',
      'PATTANAM',
      'SULUR /SOUTH',
      'Nadupalayam',
      'Kangayampalayam',
      'Vellanaipatti',
      'SULUR/EAST',
      'AGPUDUR',
      'Kannampalayam',
      'IRUGUR',
      'PALLAPALAYAM',
      'SULUR /TOWN',
      'GANDHINAGAR',
      'CHINNIAMPALAYAM/\nEAST',
    ],
    edc: 'COIMBATORE EDC/ METRO',
  },
  {
    sections: [
      'BHARATHIYAR UNIVERSITY',
      'ALANDURAI / WEST',
      'PERUR',
      'K.N.PALAYAM',
      'ALANDURAI / EAST',
      'P.N.PUDUR',
      'DEVARAYA PURAM',
      'KALAMPALAYAM',
      'THONDAMUTHUR',
      'SOMAYAM PALAYAM',
      'VEDAPPATTY',
      'KANUVAI',
      'MADAMPATTY',
      'GANESAPURAM',
      'KURUMBA PALAYAM',
      'THUDIYALUR /WEST',
      'URUMANDAM PALAYAM',
      'NSN PALAYAM',
      'JOTHIPURAM',
      'KOILPALAYAM / SOUTH',
      'PN PALAYAM/ RURAL /EAST',
      'C.V.PATTY',
      'CHINNA THADAGAM',
      'IDIGARAI',
      'P.G.PUDUR',
      'PANNIMADAI',
      'PN PALAYAM / RURAL /WEST',
      'PN PALAYAM / TOWN',
      'PN PALAYAM / TOWN / SOUTH',
      'S.V.PATTY',
      'THUDIYALU/ EAST',
      'NAICKEN PALAYAM',
      'KEERANATHAM',
      'KRISHNAPURAM',
      'VADAMADURAI',
      'ANNUR/SOUTH',
      'POGALUR',
      'DHAYANUR/ SOUTH',
      'KARAMADAI /WEST',
      'METTUPALAYAM/TOWN/NORTH',
      'METTUPALAYAM /TOWN /SOUTH',
      'SIRUMUGAI',
      'THEKKAMPATTY',
      'METTUPALAYAM / RURAL',
      'PETHIKUTTAI',
      'DHAYANUR /NORTH',
      'KARIAM PALAYAM',
      'URBAN/METTU PALAYAM',
      'KARAMADAI/ EAST',
    ],
    edc: 'COIMBATORE EDC/ NORTH',
  },
  {
    sections: [
      'NEGAMAM /NORTH',
      'NEGAMAM /RURAL',
      'KATTAMPATTY',
      'KINATHUKADAVU /WEST',
      'MILL KOVIL PALAYAM',
      'NEGAMAM /SOUTH',
      'THAMARAIKULAM',
      'KINATHUKADAV/EAST',
      'A.SANGAMPALAYAM',
      'VADACHITHUR',
      'VADAKIPALAYAM',
      'EAST/NEGAMAM',
      'MALAYADIPALAYAM',
      'K.N.PALAYAM',
      'SULTHANPET',
      'POORANDAMPALAYAM',
      'PODANUR /NORTH',
      'O.K. MANDAPAM',
      'PALATHURAI',
      'EACHANARI',
      'MADUKKARAI/NORTH',
      'PODANUR /SOUTH',
      'KUNIAMUTHUR /TOWN',
      'M.M. PATTY',
      'SUNDERAPURAM',
      'MADUKKARAI /SOUTH',
      'CHETTIPALAYAM',
      'K.G. CHAVADI',
      'KOVAIPUDUR',
      'KURICHI/ SOUTH',
      'HOUSINGUNIT',
      'ATHUPALAM',
      'VELLALORE',
      'B.K.PUDUR',
      'SOMANUR /RURAL',
      'THAKKALU/EAST',
      'MANGALAM',
      'ARASUR',
      'KOMBAKKADU',
      'P.S. PALAYAM',
      'POOMALUR',
      'SAMALAPURAM',
      'SENTHIL NAGAR',
      'SOMANUR /TOWN',
      'THAKKALUR /WEST',
      'NEELAMBUR',
      'KANIYUR',
      'KADUVETTIPALAYAM',
      'VELAYUTHAMPALAYAM',
      'CHENNIANDAVARKOIL',
      'VANJIPALAYAM',
      'A.G PUDUR',
      'K.N. PATTY',
      'VAGARAYAM PALAYAM',
    ],
    edc: 'COIMBATORE EDC/ SOUTH',
  },
  {
    sections: [
      'OOTY / ETTINES ROAD',
      'OOTY / LAKE VIEW',
      'YELLANALLI',
      'EMERALD',
      'GUDALUR /LOWER',
      'PANDALUR',
      'THUMMANATTY',
      'GUDALUR /TOWN/ SOUTH',
      'CHERAMBADI',
      'DEVARSHOLA',
      'GUDALUR /TOWN',
      'OOTY / COMMERCIAL ROAD',
      'THALAIKUNDAH',
      'TAMILAGAM',
      'DENADUCOMBAI',
      'PYKARA',
      'UPPATTY',
      'HOSPITAL ROAD/ OOTY',
      'AYYANKOLLI',
      'MASINAGUDI',
      'MOUNT ROAD/COONOOR',
      'KUNDAH',
      'KIL-KOTHAGIRI',
      'MOUNT PLEASANT/COONOOR',
      'HUBATHALAI',
      'HARUKUTCHI',
      'NEDUGULA',
      'KOTHAGIRI / TOWN',
      'ADIGARATTY',
      'SELAS',
      'ARAVENU',
      'GLENDALE/COONOOR',
      'KOTHAGIRI / RURAL',
      'SIMS PARK / COONOOR',
      'YEDAPALLI',
      'WESTBROOK',
      'BARRACKS',
      'KATTABETTU',
      'YEDAKADU',
    ],
    edc: 'NILGIRIS EDC',
  },
  {
    sections: [
      'TIRUPPUR /TOWN / WEST',
      'TIRUPPUR /TOWN / SOUTH',
      'PERUNTHOLUVU',
      'TIRUPPUR /TOWN / NORTH',
      'BAZAAR',
      'CHETTIPALAYAM',
      'BRIDGEWAY COLONY',
      'P.K. PALAYAM /SOUTH',
      'GANAPATHI PALAYAM',
      'THIRUVALLUVAR NAGAR',
      'TOWN HALL',
      'R .V.E NAGAR',
      'KASI PALAYAM',
      'EADUVAM PALAYAM',
      'KARAIPUDUR',
      'MUDALIPALAYAM',
      'NALLUR',
      'RAYAPURAM',
      'TIRUPPUR /TOWN / EAST',
      'VEERAPANDY',
      'P.K.PALAYAM /NORTH',
      'BHARATHI NAGAR',
      'PERIANDI PALAYAM',
      'KONGU NAGAR',
      'Murugampalayam',
      'Chinnakarai',
      'Arulpuram',
      'Matheswaran Nagar',
      'Nachipalayam',
      'TOWN/UTHUKULLI',
      'RURAL/UTHUKULLI',
      'EAST/UTHUKULI',
      'CHENKAPPALLI',
      'S.P.PALAYAM',
      'PERUMANALLUR/SOUTH',
      'PANDIAN NAGAR',
      'ANNUR /NORTH',
      'ANUPPARPALAYAM/RURAL',
      'AVINASHI/RURAL/EAST',
      'CHEYUR/SOUTH',
      'KARUVALUR',
      'T.M.POONDY',
      'ANUPPARPALAYAM',
      'AVINASHI/TOWN',
      'AVINASHI/WEST',
      'CHELLAPPAPALAYAM',
      'PASUR',
      'PERUMANALLUR / NORTH',
      'VADAKKALUR',
      'VADUGAPALAYAM',
      'SAMUNDIPURAM',
      'POOLUVAPATTY',
      'VENGAMEDU',
      'NAMBIAMPALAYAM',
      'CHEYUR /NORTH',
      'R.K.NAGAR',
    ],
    edc: 'TIRUPPUR EDC',
  },
  {
    sections: [
      'UDUMALPET /RURAL /WEST',
      'THUNGAVI',
      'ELAYAMUTHUR',
      'KOMARALINGAM',
      'VALAVADI',
      'UDUMALPET /RURAL / NORTH',
      'UDUMALPET /RURA/EAST',
      'POOLANKINAR',
      'MANUPATTY',
      'MADATHUKULAM',
      'DHALLY',
      'POOLAVADI',
      'KANIYUR',
      'UDUMALPET /RURAL / SOUTH',
      'GUDIMANGALAM',
      'UDUMALPET /TOWN',
      'UDUMALPET/G.NAGAR',
      'VALPARAI /SOUTH',
      'SHOLAYAR NAGAR',
      'MUDIS',
      'IYERPADI',
      'VALPARAI /NORTH',
      'KOLARPATTY',
      'ERISANAMPATTY',
      'D.V.PUDUR /WEST',
      'D.V.PUDUR/EAST',
      'SETHUMADAI',
      'ANAMALAI /TOWN',
      'RURAL/ANAIMALAI',
      'VETTAIKARANPUDUR',
      'ANGALAKURICHY',
      'THENSANGAMPALAYAM',
      'KANJAMPATTY',
      'HTSC/IYERPADI',
      'ZAMIN UTHUKULI',
      'T.KOTTAMPATTY',
      'MANNUR',
      'GOMANGALAM',
      'MUTHUR',
      'SAMATHUR',
      'POLLACHI /RURAL',
      'MARICHANAYAKAN-PALAYAM',
      'Peappampatty',
      'V.VELUR',
      'RAMACHANDRAPURAM',
      'KONGALNAGARAM',
      'RANGASAMUTHIRAM',
      'ERIPATTY',
      'POLLACHI /TOWN',
      'MAHALINGAPURAM',
    ],
    edc: 'UDUMALPET EDC',
  },
  {
    sections: [
      'TOWN/PALLADAM',
      'RURAL/PALLADAM',
      'KETHANUR',
      'SELAKARACHAL',
      'PALLADAM /TOWN / NORTH',
      'SALAIPUDUR',
      'KARADIVAVI',
      'PONGALUR /TOWN',
      'MADHAPPUR',
      'PAPPAMPATTY',
      'JALLIPATTY',
      'K.N. PURAM',
      'KODUVAI',
      'NARANAPURAM',
      'P. VADUGAPALAYAM',
      'PONGULAR /RURAL',
      'V. KALLIPALAYAM',
      'ELAVANTHY',
      'KALIVELAMPATTY',
      'SAKTHINAGAR',
      'LAKSHMINAICKEN-PALAYAM',
      'SIDDNAICKEN-PALAYAM',
      'SIVANMALAI',
      'D.N.PATTY',
      'K.M.PATTY',
      'VELLAKOIL /RURAL',
      'VELLAKOIL/EAST',
      'KANGAYAM/RURAL',
      'MUTHUR/WEST',
      'KANGAYAM /NORTH',
      'OLAPPALAYAM',
      'MUTHUR /TOWN',
      'UTHIYUR',
      'KAVANDAMPALAYAM',
      'KANGAYAM /EAST',
      'E.P.PUDUR',
      'KANGAYAM /SOUTH',
      'METTUPPALAYAM',
      'MUTHUR /RURAL',
      'NALLROAD',
      'PALAYAKOTTAI',
      'AE/TOWN/VELLAKOVIL',
      'PADIYUR',
      'VELLAKOIL /WEST',
      'DHALAVAI PATTINAM',
      'KOLATHUPALAYAM',
      'DHARAPURAM /RURAL',
      'MULANUR /RURAL',
      'KUNDADAM /SOUTH',
      'AKKARAIPALAYAM',
      'CHINNAPUTHUR',
      'KALLIVALASU',
      'KANNIVADI',
      'DHARAPURAM/EAST',
      'KUNDADAM /NORTH',
      'MADATHUPALAYAM',
      'MANAKKADAVU',
      'MANNURPALAYAM',
      'MULANUR /TOWN',
      'DHARAPURAM/NORTH',
      'SURIYANALLUR',
      'VADAGUPATTY',
      'DHARAPURAM / TOWN',
      'POONIVADI',
    ],
    edc: 'PALLADAM EDC',
  },
  {
    sections: [
      'ERODE /SOUTH',
      'THINDAL',
      'NARAYANAVALASU',
      'CHITHODE /WEST',
      'PERUNTHALAIYUR',
      'EROD/EAST',
      'NASIANOOR',
      'VEERAPPANCHATRAM /TOWN',
      'KAVINDAPADY /TOWN',
      'KAVINDAPADY /RURAL',
      'Muthampalayam',
      'Mettukadai',
      'ASOKAPURAM',
      'SOUTH/CHITHODE',
      'E.K.VALASU',
      'KANJIKOIL',
      'SALANGAPALAYAM',
      'CHITHOD/EAST',
      'ERODE /TOWN',
      'B.P.AGRAHARAM',
      'ERODE /WEST',
      'VEERAPPANCHATRAM /RURAL',
      'KARUNGAL PALAYAM',
      'SIVAGIRI/WEST',
      'THAMARIPALAYAM',
      'ERODE /RURAL',
      'KASPAPETTAI',
      'ARACHALUR',
      'SIVAGIRI/TOWN',
      'MULLAMPARAPPU',
      'GANAPATHYPALAYAM',
      'AVALPOONDURAI',
      'VADUGAPATTY',
      'SIVAGIRI/EAST',
      'KODUMUDI/SOUTH',
      'MODAKKURICHI',
      'KODUMUDI/TOWN',
      'ELUMATHUR',
      'ELLAKKDAI',
      'KANDASAMYPALAYAM',
      'K.B.GRAMAM',
      'LAKKAPURAM',
      'MUHASIHANUMANPALLY',
      'SOLAR',
      'SIPCOT',
      'EAST/VIJAYAMANGALAM',
      'M.PIDARIYUR',
      'PERUNDURAI /RURAL',
      'CHENNAIMALAI /TOWN',
      'CHENNIMALAI /WEST',
      'NALLAMPATTY',
      'P.G.PALAYAM',
      'CHENNIMALAI /RURAL',
      'VIJAYAMANGALAM',
      'KUNNATHUR /RURAL',
      'VELLODE',
      'PERUNDURAI /NORTH',
      'PERUNDURAI /TOWN',
      'KUNNATHUR /TOWN',
      'INGUR',
      'KUNNATHU/EAST',
      'PERUNDURAI /SOUTH',
      'THINGALORE',
    ],
    edc: 'ERODE EDC',
  },
  {
    sections: [
      'MECHERI EAST',
      'KOLATHUR SOUTH',
      'KUNJANDIYUR',
      'KOLATHUR NORTH',
      'SAMPALLY',
      'METTUR /TOWN',
      'MECHERI',
      'KOLATHUR RURAL',
      'METTUR /RURAL',
      'THUMBIPADDY',
      'KADAYAMPATTY II',
      'PANCHUKALIPATTY',
      'KADAYAMPATTY',
      'THOLASAMPATTY',
      'ALAGUSAMUDRAM',
      'CHINNA TRIPATHY',
      'NANAGAVALLY',
      'OMALUR TOWN',
      'VELLAR',
      'THARAMANGALAM',
      'PAPPAMPATTY',
      'VANAVASI',
      'KUPPANDAPALAYAM',
      'SANKARI RS',
      'VEPPADAI II',
      'THANNEERPANDAL PALAYAM',
      'C.N. PALAYAM',
      'KOMARAPALAYAM/RURAL',
      'PALLIPALAYAM/EAST',
      'SANKARI /RURAL',
      'KOMARAPALAYAM / SOUTH',
      'S.P.B. COLONY',
      'VEPPADAI',
      'PALLAKKAPALAYAM',
      'KOKKARAYANPET',
      'SANKARI / TOWN',
      'PALLIPALAYAM / TOWN',
      'VAIGUNDAM',
      'PALLIPALAYAM / WEST',
      'I.C.L. COLONY',
      'ETHIRMEDU',
      'KONEERIPATTY',
      'EDAPPADY/TOWN',
      'KONGANAPURAM',
      'JALAKANDAPURAM/NORTH',
      'CHITTOOR',
      'POOLAMPATTY',
      'JALAKANDAPURAM/RURAL',
      'EDAPPADY/RURAL',
      'JALAKANDAPURAM/SOUTH',
      'THEVOOR',
      'KANNANTHERI',
      'M.D.CHOULDRY',
    ],
    edc: 'METTUR EDC',
  },
  {
    sections: [
      'CENTRAL',
      'DADAGAPATTY',
      'GUGAI',
      'BAZZAR',
      'FORT',
      'THILLAINAGAR',
      'LINEMEDU',
      'NETHIMEDU_TOWN',
      'PONNAMAPET',
      'KALARAMPATTY',
      'DASANAICKENPATTY',
      'KITCHIPALAYAM',
      'SEELANAICKENPATTY',
      'VALASAIYUR',
      'SESANCHAVADY',
      'KARIPATTY',
      'VELLALAGUNDAM',
      'AYOTHIYAPATTINAM/WEST',
      'KOOTATHUPATTY',
      'VEERANAM',
      'AYOTHIYAPATTINAM/EAST',
      'UDAYAPATTY',
      'MARAVANERI',
      'GORIMEDU',
      'AMMAPET',
      'HASTHAMPATTY',
      'KANNANKURUCHI',
      'YERCAUD-I',
      'YERCAUD-II (New Section)',
      'ARISIPALAYAM',
      'MEYYANOOR',
      'SWARNAPURI',
      'SURAMANGALAM',
      'SHEVAPET',
      'NARASOTHIPATTI',
      'THIRUVAGOUNDANOOR',
      'SIVATHAPURAM',
      'MUTHUNAICKENPATTI-I',
      'NETHIMEDU-RURAL',
      'MALLAMOOPANPATTI',
      'KARRUPUR',
      'KONDALAMPATTI',
      'MUTHUNAIKENPATTI-II',
      'VELLALAPATTY',
      'VEERAPANDY',
      'ATTAYAMPATTY-TOWN',
      'ALAVAIPATTY',
      'ARIYANOOR',
      'ATTAYAMPATTY-RURAL',
      'EDANGANASALAI',
      'VEMBADITHALAM',
      'MALLUR-RURAL',
      'MALLUR-WEST',
      'PANAMARATHUPATTY-RURAL',
      'PANAMARATHUPATTY-TOWN',
      'PERUMADGOUNDAMPATTY',
      'VENNANDUR-RURAL',
      'VENNANDUR-TOWN',
      'ELAMPILLAI',
      'K.K.NAGAR',
      'N.S.PURAM-RURAL',
      'DEVIAKURUCHI',
      'SIRUVACHUR',
      'ARAGALUR',
      'ATTUR-RURAL',
      'ATTUR-SOUTH',
      'KADAMBUR',
      'KAMAKAPALAM',
      'KATTUKOTAI',
      'KUDAMALAI',
      'M.V.COLONY',
      'MANJINI',
      'N.S.PURAM-TOWN',
      'PULIANKURUCHI',
      'THANDAVARAYAPURAM',
      'THALAIVASAL',
      'THEDAVOOR',
      'VERAGANOOR',
      'SATHAPADDY',
      'GANGAVALLY',
      'ATTUR-NORTH',
      'ATTUR-TOWN',
      'THAMMAMPATTI-TOWN',
      'ETHAPOOR',
      'KEERIPATTY',
      'MALLIAKARAI',
      'MANGALAPURAM',
      'P.N.PALAYAM',
      'SENDARAPATTY',
      'THAMMAMPATTI-RURAL',
      'THIMMANAICKENPATTI',
      'THUMBAL',
      'VALAPADY-SOUTH',
      'BELUR-I',
      'BELUR-II',
      'BELUR-WEST',
      'ULLIPURAM',
      'VALAPADY-NORTH',
      'THIRUMANOOR',
      'P.G.PALAYAM',
      'KARUMANDURAI',
    ],
    edc: 'SALEM EDC',
  },
  {
    sections: [
      'RASIPURAM-TOWN',
      'ANDALOORGATE',
      'RASIPURAM-RURAL',
      'GURUSAMYPALAYAM',
      'ARIYAGOUNDAMPATTY',
      'ATHANOOR',
      'KAKKAVERI',
      'METTALA',
      'MULLUKURICHI',
      'PATTANAM',
      'R.PUDHUPALAYAM',
      'R.PUDUPATTY',
      'SINGALANTHAPURAM',
      'T.JEDARPALAYAM',
      'NAMAGIRIPET-I',
      'NAMAGIRIPET-II',
      'DEVANANKURICHI',
      'THIRUCHENGODE / CENTRAL',
      'VAIYAPPAMALAI/EAST',
      'KUMARAMANGALAM',
      'MALLASAMUDRAM / RURAL',
      'THIRUCHENGODE / SOUTH',
      'VAIYAPPAMALAI/WEST',
      'THIRUCHENGODE / RURAL',
      'THIRUCHENGODE /NORTH',
      'KALIPPATTI',
      'ELACHIPALAYAM',
      'MOORPALAYAM',
      'THIRUCHENGODE /EAST',
      'UNJANAI',
      'KOOTAPALLI',
      'MALLASAMUDRAM / TOWN',
      'MANICKAMPALAYAM',
      'VELAGOUNDAMPATTY',
      'PERIYAMANALI',
      'PUDANCHANDAI',
      'NAMAKKAL /TOWN /SOUTH',
      'POTTIREDDIPATTY',
      'NAMAKKAL /RURAL/NORTH',
      'SENDAMANGALAM / NORTH',
      'THATHATHIRIPURAM',
      'THIRUMALAIPATTY',
      'ERUMAPATTY',
      'PUDUCHATRAM',
      'NAMAKKAL /RURAL/WEST',
      'KALANGANI',
      'NALLIPALAYAM',
      'SENDAMANGALAM / SOUTH',
      'BELUKURICHI',
      'KOLLIHILLS II',
      'KARAVALLI',
      'KOLLI HILLS',
      'NAMAKKAL/RURAL/SOUTH',
      'MUTHUGAPPATTY',
      'NAMAKKAL /TOWN /NORTH',
      'VALAYAPATTY',
      'VARAGUR',
      'KOSAVAMPATTY',
      'K.N.PATTY / TOWN',
      'PUDUPPATTY',
      'POTHANUR',
      'MOHANUR / TOWN',
      'JADARPALAYAM',
      'ANNIYAPURAM',
      'MOHANUR / RURAL',
      'P.VELUR / TOWN',
      'PALAPATTY',
      'PARAMATHY',
      'KABILARMALAI',
      'PANDAMANGALAM',
      'KANDAMPALAYAM',
      'CHITALANDUR',
      'PILLIKALPALAYAM',
      'KEERAMBUR',
      'SOLASIRAMANI',
      'NADANTHAI',
    ],
    edc: 'NAMAKKAL EDC',
  },
  {
    sections: [
      'BURGUR',
      'ANDHIYUR /NORTH',
      'BHAVANI /NORTH',
      'BHAVANI/RURAL',
      'K R PALAYAM',
      'CHITHAR',
      'ANDHIYUR /SOUTH',
      'OLAGADAM',
      'BHAVANI/WEST',
      'AMMAPET',
      'VELLITHIRUPUR',
      'GURUVAREDDIYUR',
      'POONACHI',
      'CHENNAPATTY',
      'JAMBAI',
      'BHAVANI /TOWN',
      'THAVITTUPALAYAM',
      'ANDHIYUR/TOWN',
      'OTHAKUTHARAI',
      'ATHANI/EAST',
      'T.N.PALAYAM',
      'ATHANI /WEST',
      'GETTICHEVIYUR',
      'SIRUVALUR',
      'NAMBIYUR/EAST',
      'KURUMANDUR',
      'KALLIPATTY',
      'KARATTADIPALAYAM',
      'GOBI /SOUTH',
      'GOBI /NORTH',
      'PUDUPALAYAM',
      'KOLAPALUR',
      'KUGALUR',
      'GOBI /TOWN',
      'KASIPALAYAM',
      'NAMBIYUR /NORTH',
      'NAMBIYUR /WEST',
      'CHIMITTAHALLY',
      'THALAVADY /SOUTH',
      'THALAVAD/EAST',
      'KADAMBUR',
      'D.G.PUDUR',
      'KAVILIPALAYAM',
      'UKKARAM',
      'SIKKARASAM PALAYAM',
      'KODIVERY',
      'KENJANOOR',
      'PUNGAMPALLY',
      'PONNAMPALAYAM',
      'ARASUR',
      'KEMBANAIKKANPALAYAM',
      'RAJAN NAGAR',
      'BHAVANISAGAR',
      'M/SOUTH/SATHY',
      'SATHY /NORTH',
      'PULIAMPATTY /TOWN',
    ],
    edc: 'GOBICHETTIPALAYAM EDC',
  },
  {
    sections: [
      'ARIYUR',
      'USOOR',
      'VIRINJIPURAM',
      'SATHUMADURAI',
      'KILARASAMPET',
      'KILPALLIPET',
      'KANIYAMBADI',
      'VELLORE / WEST',
      'SATHUVACHARI/WEST',
      'SALAVANPET',
      'BAGAYAM',
      'THOTTAPALAYAM',
      'KAGITHAPATTARAI',
      'VELLORE CENTRE',
      'SANKARANPALAYAM',
      'SHENBAKKAM',
      'SATHUVACHARI/EAST',
      'LATTARI',
      'MITTOR',
      'K.V.KUPPAM',
      'KARNAMBUTTU',
      'MELPADI',
      'SENJI',
      'THIRUVALAM',
      'VADUGANTHANGAL',
      'P.K.PURAM',
      'DHARAPADAVEDU',
      'GANDHINAGER/WEST',
      'GANDHINAGER/EAST',
      'KATPADI',
      'AMMOOR',
      'BHEL',
      'NAVALPUR',
      'RANIPET - RURAL',
      'OZHUGHUR',
      'SIPCOT - WEST',
      'WALAJAPET RURAL',
      'SIPCOT-EAST',
      'SIDCO',
      'CHAKRAMALLUR',
      'WALAJAPET URBAN',
      'RANIPET - URBAN II',
      'RANIPET - URBAN I',
      'KATHIYAVADI',
      'K. VELUR',
      'MAMBAKKAM',
      'THIMIRI /WEST',
      'VILAPAKKAM',
      'KALAVAI /TOWN',
      'THIMIRI/EAST',
      'KAVNOOR',
      'PENNAGAR',
      'RATHINAGIRI',
      'KALAVAI/EAST',
      'VALAPANDAL',
      'VIZHARAM',
      'ARCOT /WEST',
      'ARCOT/EAST',
      'SHOLINGHUR - RURAL',
      'OCHERI',
      'KODAIKAL',
      'KAVERIPAKKAM',
      'M.V. PURAM',
      'Banavaram',
      'PANAPAKKAM - RURAL',
      'PONNAI',
      'PANAPAKKAM - TOWN',
      'SHOLINGHUR - TOWN',
      'GURUVARAJPET',
      'PALLUR /WEST',
      'ARAKKONAM /RURA/EAST',
      'NEMILI /WEST',
      'THAKKOLAM /RURAL',
      'ARAKKONAM /RURAL /NORTH',
      'NAGAVEDU',
      'THAKKOLAM /TOWN',
      'ARIKILAPADI',
      'ARAKKONAM /RURAL /SOUTH',
      'KUMPINIPET',
      'MINNAL',
      'NEMIL/EAST',
      'PUNNAI /SS',
      'ARAKKONAM /TOWN',
    ],
    edc: 'VELLORE EDC',
  },
  {
    sections: [
      'KURUSILAPET',
      'TIRUPATTUR /NORTH',
      'TIRUPATTUR /WEST',
      'KANDILI',
      'MITTUR',
      'KORATTY',
      'SUNDARAMPALLY',
      'PERAMBUT',
      'VISHAMANGALAM',
      'VENGALAPURAM',
      'MADAVALAM',
      'SINGARAPET',
      'KUNICHI',
      'PUDUPET/EAST',
      'PUDUPET /WEST',
      'TIRUPATTUR /TOWN II',
      'TIRUPATTUR /TOWN I',
      'VELLAKUTTAI',
      'VALAYAMPET',
      'NATRAMAPALLI /SOUTH',
      'AMBALUR',
      'KETHANDAPATTY',
      'VANIYAMBADI /NORTH',
      'VADACHERI',
      'JOLARPET',
      'JOLARPET HILLS ',
      'ALANGAYAM',
      'ALANGAYAM HILLS ',
      'VANIYAMBADI /TOWN',
      'VANIYAMBADI /SOUTH',
      'NATRAMPALLI /NORTH',
      'UDEYANDRAM',
      'PERUMALPET',
      'ALINJIKUPPAM',
      'KARUMBUR',
      'ALANKUPPAM',
      'ODUGATHUR /WEST',
      'AGARAMCHERI',
      'ANAICUT /RURAL',
      'ANAICUT /TOWN',
      'ODUGATHUR/EAST',
      'PALLIKONDA',
      'VINNAMANGALAM',
      'MADANUR',
      'THUTHIPET',
      'VEPPANKUPPAM',
      'AMBUR /TOWN',
      'AMBUR /SS',
      'AMBUR /RURAL /SOUTH',
      'AMBUR /WEST',
      'MELPATTY',
      'NELLOREPET',
      'PAKKAM',
      'R.GOLLAPALLY',
      'PARADARAMI',
      'GUDIYATHAM /SOUTH',
      'MELALATHUR',
      'NARIYAMBUT',
      'PERNAMBUT /RURAL',
      'PERNAMBUT /SOUTH',
      'GUDIYATHAM/EAST',
      'VALATHUR',
      'CHINNAVARIGAM',
      'GUDIYATHAM /URBAN I',
      'PERNAMBUT /TOWN',
      'GUDIYATHAM /URBAN II',
    ],
    edc: 'TIRUPATHUR EDC',
  },
  {
    sections: [
      'PALAYAMPUDUR',
      'DHARMAPURI /NORTH',
      'DHARMAPURI /RURAL',
      'ATHIYAMANKOTTAI',
      'SOLAIKOTTAI',
      'INDUR',
      'PERUMBALAI',
      'EARIYUR',
      'NALLAMPALLY',
      'KRISHNAPURAM',
      'PENNAGARAM /RURAL',
      'PULIKARAI',
      'DHARMAPUR/EAST',
      'PENNAGARAM /TOWN',
      'DHARMAPURI /WEST',
      'DHARMAPURI / FORT',
      'LAKKIAMPATTY',
      'PAPPARAPATTY /RURAL',
      'SOTH/RAYAKOTTAI',
      'MARANDAHALLY/RURAL I',
      'MATLAMPATTY',
      'KARIMANGALAM /RURAL',
      'RAYAKOTTAI',
      'PALACODE /RURAL',
      'MARANDAHALLY /RURAL II',
      'PAPPARAPATTY /TOWN',
      'MARANDAHALLY /TOWN',
      'KARIMANGALAM /TOWN',
      'PALACODE /TOWN',
      'NARIPALLY',
      'KOTTAPATTY',
      'HARUR /SOUTH',
      'GOBINATHAMPATTY',
      'HARUR/EAST',
      'HARUR /NORTH',
      'PAPPIREDDIPATTY / TOWN',
      'HARUR /WEST',
      'PAPPIREDDIPATTY / RURAL',
      'HANUMANTHEERTHAM',
      'THEERTHAMALAI',
      'A.PALLIPATTY',
      'HARUR /TOWN',
      'DODDAMPATTY',
      'RAMIYANAHALLY',
      'BOMMIDI /NORTH',
      'BOMMIDI /SOUTH',
      'CHINTHALPADI',
      'KAMBAINALLUR-II',
      'MORAPPUR',
      'KADATHU/EAST',
      'BAIRANATHAM',
      'BOMMIDI /TOWN',
      'KAMBAINALLUR-I',
      'KADATHUR /NORTH',
    ],
    edc: 'DHARMAPURI EDC',
  },
  {
    sections: [
      'VEPPANAPALLY',
      'KRISHNAGIRI/OLDPET',
      'ALAPATTY',
      'SHOOLAGIRI-II/ RURAL',
      'KAVERIPATTINAM /\nRURAL II/ NORTH',
      'M.C.PALLY',
      'SHOOLAGIRI-I/TOWN',
      'KRISHNAGIRI /TOWN/ RURAL-I',
      'KAVERIPATTINAM /TOWN',
      'KAVERIPATTINAM /RURAL I/ SOUTH',
      'KRISHNAGIRI /RURAL-II',
      'KRISHNAGIRI /RURAL III/INDL. ESTATE',
      'AVATHANAPATTY',
      'KUTTIGOUNDANOOR',
      'CHINNAR',
      'GURUBARAPALLY',
      'HOSUR /WEST',
      'HOSUR/SIPCOT-I',
      'DENKANIKOTTAI/ SOUTH',
      'HOSUR /SOUTH',
      'HOSUR/SIPCOT-II',
      'HOSUR /RURAL',
      'KELAMANGALAM',
      'BERIGAI',
      'DENKANIKOTTAI/ NORTH',
      'MATHIGIRI',
      'HOSUR/CENTRAL',
      'HOSUR/SIDCO',
      'THALLY',
      'BAGALUR/TOWN',
      'UDDANAPALLY',
      'BELAGONDAPALLY',
      'ANDIVADI',
      'ARASANATTY',
      'BAGALUR / RURAL',
      'ANCHETTY',
      'BATHALAPALLY',
      'HOSUR / NORTH',
      'MADHAGONDAPALLY',
      'EAST/KELAMANGALAM',
      'JAWALAGIRI',
      'ATHIMUGAM',
      'ELECOT/HOSUR',
      'ARASAMPATTY',
      'MATHUR-II',
      'UTHANGARAI-I',
      'KALLAVI',
      'SANTHUR',
      'VARATANAPALLY',
      'JAGADEVI',
      'UTHANGARAI-II',
      'NAGARASAMPATTY',
      'BARGUR-II/RURAL',
      'BARGUR/ SIDCO',
      'KARAPATTU',
      'SAMALPATTY',
      'POCHAMPALLY',
      'MATHUR-I',
      'BARGUR-I/TOWN',
      'ELATHAGIRI',
      'PANNANTHUR',
    ],
    edc: 'KRISHNAGIRI EDC',
  },
  {
    sections: [
      'VANUR',
      'Kodur',
      'MANDAGAPATTU',
      'RAMPAKKAM',
      'PAKKAM',
      'THIRUCHITRAMBALAM / SOUTH',
      'V.AGARAM',
      'THIRUCHITRAMBALAM / NORTH',
      'MOKSHAKULAM',
      'KOLIYANUR',
      'KANDAMANGALA/EAST',
      'VALAVANUR /RURAL',
      'SORANAVAUR',
      'KANDAMANGALAM / WEST',
      'VALAVANUR /URBAN',
      'KOTTAKUPPAM',
      'PILLUR',
      'MUNDIYAMPAKKAM',
      'PANAYAPURAM',
      'ANNIYUR',
      'KEDAR',
      'VILLUPURAM / RURA/EAST',
      'VILLUPURAM /WEST',
      'VIKRAVANDI',
      'POOTHAMEDU',
      'CHITHALAMPET',
      'KANAI',
      'KAPPUR',
      'NEMUR',
      'RADHAPURAM',
      'THUMBUR',
      'VALUDAVUR',
      'VIKRAVANDY /WEST',
      'VILLUPURAM /RURAL /   SOUTH',
      'Town-3/ Villupuram',
      'Town-2/Villupuram',
      'Town-1/Villupuram',
      'JANIKIPURAM',
      'MANUR',
      'VELLIMEDUPETTAI',
      'UPPUVELLORE',
      'PERIYATHACHUR',
      'V.PARANGINI',
      'VEEDUR',
      'CHENDUR',
      'KOLLAR',
      'PATTINAM',
      'RETTANAI',
      'SARAM',
      'VADASIRUVALLUR',
      'KILIYANUR',
      'MURUKKERY',
      'DEEVANUR',
      'Jaggampettai',
      'Annumandai',
      'MAILAM',
      'BRAMADESAM',
      'Avanipur',
      'Thiruvakkarai',
      'TINDIVANAM /TOWN II',
      'TINDIVANAM /TOWN I',
      'MARAKKANAM',
      'GUDUVAMPOONDY',
      'NALLANPILLLAIPETRAL',
      'PENNAGAR',
      'SATHIYAMANGALAM',
      'T.V.SAMUTHIRAM',
      'VALLAM',
      'ALAMPOONDY',
      'EYYIL',
      'EDAPET',
      'EACHUR',
      'DEVANUR',
      'AVALURPET /TOWN',
      'MUDAIYUR',
      'Thayanur',
      'SITHAMPOONDY',
      'ANANTHAPURAM',
      'GING EE/TOWN',
    ],
    edc: 'VILLUPURAM EDC',
  },
  {
    sections: [
      'TIRUVANNAMALAI /TOWN / EAST',
      'THANDRAMPET /SOUTH',
      'THANIPADI /SS',
      'KILNATHUR',
      'TIRUVANNAMALAI /RURAL II',
      'THANDRAMPET /NORTH',
      'NORTHAMPOONDI',
      'THANIPADI /RURAL',
      'OTTAKUDISAL',
      'TIRUVANNAMALAI /TOWN /WEST',
      'VANAPURAM',
      'MALLAVADY',
      'THIRUVANNAMALAI/TOWN/NORTH',
      'PERUNGALATHUR',
      'TIRUVANNAMALAI /RURA/EAST',
      'TIRUVANNAMALAI /RURAL I',
      'THAMARAI NAGAR',
      'NALLAVANPALAYAM',
      'REDDIYARPALAYAM',
      'KOLAPPALUR /RURAL',
      'DEVIKAPURAM /TOWN',
      'DEVIKAPURAM /WEST',
      'CHETPET /TOWN',
      'DEVIKAPURA/EAST',
      'KOTTUPAKKAM',
      'KOLAPPALUR /SS',
      'MAZHAIYUR /SS',
      'CHETPET/CENTRAL',
      'MADAM',
      'PERANAMALLUR',
      'KANJI',
      'CHENGAM /TOWN',
      'KARAPET',
      'KOTTAKULAM',
      'PARAMANANDAL',
      'PACHAL',
      'KADALADI',
      'MELPALLIPET',
      'PERIYAKULAM',
      'CHENGAM /RURAL',
      'PUDUPALAYAM',
      'POLUR /TOWN',
      'POLUR /RURAL /WEST',
      'KALASAPAKKAM',
      'ATHIMOOR',
      'MANDAKOLATHUR',
      'ADAMANGALAM /RURAL',
      'LADAVARAM',
      'POLUR /RURA/EAST',
      'POLUR /RURAL /NORTH',
      'NAIDUMANGALAM /RURAL',
      'ADAMANGALAM /SS',
      'VADAMATHIMANGALAM',
      'VILVARANI',
      'NAIDUMANGALAM /SS',
      'JAMUNAMARATHUR',
      'ONNUPURAM',
      'ARNI /RURAL /WEST',
      'ARNI /RURAL /SOUTH',
      'KATTUKANALLUR',
      'SANTHAVASAL /WEST',
      'KELUR',
      'KANNAMANGALAM',
      'SANTHAVASA/EAST',
      'VELLERI',
      'ARNI /TOWN /WEST',
      'IRUMBEDU',
      'KALAMBUR',
      'PADAVEDU',
      'SEVOOR',
      'SV NAGARAM',
      'THIRUMANI',
      'VETTIYANTHOZHUVAM',
      'ARNI /TOWN /EAST',
      'ARNI /RURA/EAST',
      'ARNI/TOWN/NORTH',
      'KOVILUR',
      'PALLI',
      'MAMANDUR',
      'TIRUVATTIPURAM / RURA/EAST',
      'MADURAI',
      'PERUNAGAR /SOUTH',
      'PERUNGATTUR',
      'VEMBAKKAM',
      'BRAMMADESA/EAST',
      'TIRUVATTIPURAM /TOWN',
      'KORKAI',
      'SENGADU',
      'TIRUVATTIPURAM /RURAL / SOUTH',
      'IYYENKARKULAM',
      'RANDAM',
      'AKKUR',
      'BRAMMADESAM /WEST',
      'TIRUVATTIPURAM /RURAL /NORTH',
      'NALLUR',
      'KILKODUNGALLUR',
      'DESUR',
      'THELLAR /RURAL',
      'VALLAM',
      'PONNUR',
      'PURISAI',
      'VANDAVASI /NORTH',
      'VANDAVASI /SOUTH',
      'ANAIBOGI',
      'THELLAR /TOWN',
      'OSOOR',
      'MARUTHADU',
      'VERAIYUR',
      'MANSURAPATH',
      'KILPENNATHUR /SOUTH',
      'VETTAVALAM /TOWN',
      'TIRUVANNAMALAI /RURAL /SOUTH',
      'MANGALAM',
      'MEKKALUR',
      'KONALUR',
      'KILPENNATHUR /NORTH',
      'THATCHAMPET',
      'AVOOR',
      'RAJANTHANGAL',
      'SOMASIPADI',
      'VEDANTHAVADY',
      'NOOKAMPADI',
    ],
    edc: 'TIRUVANNAMALAI EDC',
  },
  {
    sections: [
      'PUDUPALAYAM /URBAN',
      'CUDDALORE /RURAL /WEST',
      'NALLATHUR',
      'PUDUKADAI',
      'CUDDALORE/RURAL/NORTH',
      'ALAPAKKAM',
      'SUBRAMANIYAPURAM',
      'ANNAVALI',
      'THIRUPAPULIYUR',
      'MANJAKUPPAM /RURAL',
      'VAZHISODHANAIPALAYAM',
      'CUD PORT /TOWN',
      'S.PUDUR',
      'SEMMANKUPPAAM',
      'MANJAKUPPAM /URBAN',
      'JOTHINAGAR/RURAL',
      'PANRUTI /RURA/EAST',
      'THIRUVAMUR',
      'ORAIYUR',
      'KADAMPULIYUR',
      'PANRUTI /RURAL /NORTH',
      'KANDRAKOTTAI',
      'THIRUTHURAUYUR',
      'PANRUTI /RURAL /SOUTH',
      'PUDUPETTAI',
      'V.P NALLUR',
      'PANRUTI /TOWN',
      'RURAL/ MARUNGUR',
      'MARUNGUR',
      'ANGUCHETTIPALAYAM',
      'NELLIKUPPAM /RURAL',
      'MELPATTAMPAKKAAM',
      'KOZHIPAKKAM',
      'NADUVEERAPPATU',
      'KUTTIYANKUPPAAM',
      'C.N.PALAYAM',
      'ARUNGUNAM',
      'NELLIKUPPAM /URBAN',
      'PALUR',
      'VARAKALPATTU',
      'KURINGIPADY /URBAN',
      'VENGADAMPETTAI',
      'SFC/VADAKUTHU',
      'KULLANCHAVADY /RURAL',
      'KULLANCHAVADY /URBAN',
      'GANGAIKONDAN',
      'VADALUR',
      'KURINJIPADY /RURAL',
      'KURINJIPADY /RURAL/NORTH',
      'VADALUR/RURAL',
      'CHIDAMBARAM /RURAL',
      'SETHIYATHOPE /SOUTH',
      'KANUR',
      'KUMARATCHI',
      'SETHYIATHOP/EAST',
      'LALPET',
      'SRIMUSHNAM',
      'PALAYANKOTTAI',
      'SOZHATHARAM',
      'CHIDAMBARAM/URBAN/EAST',
      'CHIDAMBARAM/URBAN/SOUTH',
      'BHUVANAGIRI',
      'PORTONOVO',
      'KEERAPALAYAM',
      'SRIMUSHNAM /RURAL',
      'KATTU MANNAR KOIL',
      'CHIDAMBARAM/URBAN/WEST',
      'MUGANTHERIYANKUPPAM',
      'VANAMADEVI',
      'ANNAMALAINAGAR',
      'B.MUTLUR',
      'MUTTAM',
      'VIRDHACHALAM /TOWN',
      'CHITHOOR',
      'M.PARUR',
      'MANGALAMPET',
      'SATHYIAVADI',
      'KO.POVANUR',
      'VIRDHACHALAM /NORTH',
      'ALADI',
      'VRIDHACHALAM /WEST',
      'KAMMAPURAM',
      'RAJENDIRAPATTINAM',
      'TITTAKUDI /SS',
      'VRIDHACHALAM/EAST',
      'NALLUR',
      'KARMAANKUDI',
      'TITTAGUDI/RURAL',
      'U.MANGALAM',
      'KANDIYAKUPPAM/URBAN',
      'KV KURICHI',
      'PENNADAM /URBAN',
      'PENNADAAM /WEST',
      'PENNADAAM/EAST',
      'MURUGANKUDI',
      'AVINANGUDI',
      'VEPPUR/SOUTH',
      'VEPPUR /WEST',
      'ADARI',
      'SIRUPAKKAM',
      'MANGALURE',
      'KALPOONDI',
      'THOLUDUR',
    ],
    edc: 'CUDDALORE EDC',
  },
  {
    sections: [
      'RISHIVANDIYAM',
      'VEERAPONDY',
      'MUGAIYUR',
      'THIRUPALAPANDAL',
      'KANDACHIPURAM',
      'G.ARIYUR',
      'THIYAGADURGAM /RURAL',
      'THIRUKOILUR /WEST',
      'SIDHALINGAMADAM',
      'ARAKANDANALLUR',
      'THIRUKOILUR /TOWN I',
      'THIYAGADURGAM /TOWN',
      'MANALURPET',
      'THIRUKOILUR /TOWN II',
      'VELLIMALAI',
      'SADAYAMPATTU',
      'CHINNASALEM /RURAL',
      'KALLAKURICHI /SOUTH',
      'NAINARPALAYAM',
      'THOTTIYAM',
      'RAYAPPANUR',
      'Eduthavainatham',
      'NORTH/Kallakurichi',
      'Kalasamuthiram',
      'Mathur',
      'Virugavoor',
      'Nagalur',
      'Siruvathur',
      'Melnariyappanur',
      'CHINNASALEM /TOWN',
      'KALLAKURICHI /TOWN',
      'KACHIRAPALAYAM',
      'Rural/Emapper',
      'PILLAYARKUPPAM',
      'ANATHUR',
      'ARASUR',
      'ASANUR',
      'ERAIYUR',
      'MADAPATTU',
      'Rural/West/Ulundurpet',
      'SENTHANADU',
      'ELAVANASOORKOTTAI',
      'THIRUNAVALUR',
      'SENKURICHY',
      'RURALNORTH/T.v.nallur',
      'T.V.NALLUR /RURAL',
      'PERIYASEVALAI',
      'T.V.NALLUR /TOWN',
      'TOWN/Ulundurpet',
      'ARASAMPATTU',
      'ARIYALUR',
      'VADAPONPARAPPI',
      'KADUVANUR',
      'O&M/Moongilthuraipattu',
      'Alathur',
      'PUDUPATTU',
      'Pandalam',
      'SANKARAPURAM /TOWN',
      'SERAPATTU',
    ],
    edc: 'KALLAKURICHI EDC',
  },
  {
    sections: [
      'THIRUKALUKUNDRAM/EAST',
      'NERUMBUR',
      'CHENGALPATTU /RURAL /SOUTH',
      'THIRUKALUKUNDRAM /SOUTH',
      'SALAVAKKAM',
      'ANJUR',
      'NENMELI',
      'SADRAS',
      'MAMALLAPURAM',
      'MEYYUR',
      'MANAMPATHY',
      'ATHUR',
      'BUKKATHURAI',
      'CHENGALPATTU /NORTH (Chettipunniyam)',
      'SINGAPERUMAL KOIL',
      'ANNA NAGAR',
      'CHENGALPATTU /TOWN',
      'THIRUKALUKUNDRAM /TOWN',
      'Z.ENDATHUR',
      'MADURANTHAGAM /RURAL',
      'L.ENDATHUR',
      'KADUGUPATTU /TOWN',
      'KADUGUPATTU /RURAL',
      'CHEYYUR-II',
      'CHEYYUR-I',
      'KOOVATHUR',
      'MADURANTHAGAM /WEST',
      'KADAPAKKAM',
      'BUDUR',
      'MADURANTAGAM/TOWN',
      'KARUNGUZHI',
      'MANNUR',
      'SOUTH/SVCHATHRAM',
      'NORTH/SV CHATHRAM',
      'SRIPERUMBUDUR /NORTH',
      'THANDALAM',
      'ORAGADAM',
      'IRUNGATTUKOTTAI',
      'SIPCOT-MAMBAKKAM',
      'VALLAKKOTTAI',
      'PILLAIPAKKAM',
      'SRIPERUMBUDUR /SOUTH',
      'PADAPPAI',
      'PADUR',
      'URAPAKKAM / EAST',
      'KOLAPAKKAM',
      'KANDIGAI',
      'KELAMBAKKAM',
      'KOVALAM',
      'KARASANGAL',
      'ALATHUR',
      'KATTANKOLATHUR',
      'SEMBAKKAM',
      'MAMBAKKAM',
      'GUDUVANCHEERY /WEST',
      'GUDUVANCHERRY / TOWN',
      'MARAIMALAI NAGAR - I',
      'GUDUVANCHEER/EAST',
      'URAPAKKAM / WEST',
      'MARAIMALAI NAGAR - II',
      'THIRUPORUR',
      'VANDALUR',
      'CHITHAMOOR',
      'NUGUMBAL',
      'ACHARAPAKKAM /RURAL',
      'THOZHUPEDU',
      'CHUNAMBEDU',
      'ORATHY - II',
      'ORATHY - I',
      'MELMARUVATHUR',
      'ELAPAKKAM',
      'ACHARAPAKKAM /TOWN',
      'KADAMBATHUR / TOWN',
      'VELLIYUR',
      'THIRUVELANGADU',
      'THIRUNINRAVOOR / RURAL',
      'SEVAPET',
      'SEMBARAMBAKKAM',
      'PERAMBAKKAM / SOUTH',
      'PERAMBAKKAM / NORTH',
      'KADAMBATHUR / RUAL',
      'SIDCO-SEMBARAMBAKKAM',
      'KILANUR',
      'PAKKAM',
      'THIRUNINRAVOOR / URBAN',
      'THIRUNINRAVOOR / NORTH',
      'THIRUMAZHISAI',
    ],
    edc: 'CHENGALPATTU EDC',
  },
  {
    sections: [
      'SHANKARAMUTT',
      'ORIKKAI',
      'PALLUR /NORTH',
      'PARANDUR',
      'SIVAKANCHI',
      'KANCHIPURAM/RURAL/SOUTH',
      'KANCHIPURAM/RURAL/NORTH',
      'KANCHIPURAM/RURAL/WEST',
      'VISHNUKANCHI',
      'PILLAYARPALAYAM',
      'NEERVALUR',
      'WHITEGATE',
      'IYYEMPETTAI',
      'NELVOY',
      'UTHIRAMERUR/RURAL',
      'WALAJABAD/TOWN',
      'BALUCHETTY CHATRAM',
      'KALIYAMPOONDI',
      'MAGARAL',
      'MANAMPATHY',
      'PERUNAGAR',
      'THIRUMMUKUDAL',
      'UTHIRAMERUR/TOWN',
      'WALAJABAD/RURAL',
      'MUSARAVAKKAM',
      'DAMAL',
      'RURAL/PALUR',
      'ELAYANARVELLORE',
      'TIRUTTANI/RURAL/EAST',
      'K.G.KANDIGAI',
      'A.J.PET/TOWN',
      'R.K.PET',
      'PODATTURPET',
      'TIRUTTANI/TOWN2',
      'AMMAYARKUPPAM',
      'TIRUTTANI/RURAL/NORTH',
      'TIRUTTANI/RURAL/WEST',
      'A.J.PET /RURAL',
      'PALLIPET',
      'ERUMBI /WEST',
      'K.K.CHATRAM',
      'V.PUDUR',
      'POONIMANGADU',
      'TIRUTTANI/TOWN1',
      'RURAL/PODATTURPET',
      'TRIVELLORE /NORTH',
      'POONDI',
      'VENGAL',
      'UTHUKOTTAI/WEST',
      'TRIVELLORE /TOWN',
      'TRIVELLORE/RURAL',
      'UTHUKOTTAI/RURAL',
      'PALAVAKKAM',
      'PERIYAPALAYAM/WEST',
      'RAMANJERI',
      'MANAVALANAGAR',
      'PERIYAPALAYAM/SOUTH',
      'BANDIKAVANOOR',
      'UTHUKOTTAI /SS',
      'TRIVELLORE/WEST',
      'KANNIGAIPAIR',
      'URBAN/PERIYAKUPPAM',
    ],
    edc: 'KANCHEEPURAM EDC',
  },
];
