import React, { useState, useEffect } from "react";
import "./main.scss";

function DataTable(props) {
    const [userData, setUserData] = useState({});
    const [user, setUser] = useState("admin");

    useEffect(() => {
        const userDetail = JSON.parse(localStorage.getItem("userdetails"));
        const userData = userDetail.userData;
        setUserData(userData);
    }, []);

    useEffect(() => {
        console.log("Updated user value:", user);
    }, [user]);

    const getTableBodyElement = () => {
        const { onRowSelect, tableDatas, onChangeUserStatus } = props;

        const onChangeUserType = (userId, newUserType) => {
            console.log(`User ${userId} type changed to: ${newUserType}`);
            setUser(newUserType);
        };

        return tableDatas.map((tableData) => {
            const tabelDataArray = [
                tableData.id,
                tableData.first_name,
                tableData.designation,
                tableData.division,
                tableData.qualification,
                tableData.contact_number,
                tableData.subscription ? "Auto" : "",
            ];

            const handleApproveClick = () => {
                console.log(`Approved user ${tableData.id} with type: ${user}`);
                onChangeUserStatus(tableData.id, "approved", user); // Pass 'user' here
            };

            const handleRejectClick = () => {
                onChangeUserStatus(tableData.id, "rejected", user); // Pass 'user' here
            };

            return (
                <tr key={tableData.id}>
                    {tabelDataArray.map((data, index) => (
                        <td key={index} onClick={() => onRowSelect(tableData.id)}>
                            {data}
                        </td>
                    ))}
                    <td>
                        <select
                            value={tableData.user_type}
                            onChange={(e) => onChangeUserType(tableData.id, e.target.value)}
                        >
                            <option value="admin">Admin</option>
                            <option value="super_admin">Super Admin</option>
                            <option value="district_admin">District Admin</option>
                            <option value="user">User</option>
                        </select>
                    </td>
                    <td>
                        <div className="d-flex">
                            {tableData.admin_approve_status !== "active" && (
                                <button
                                    className="btn primary"
                                    onClick={handleApproveClick}
                                >
                                    Approve
                                </button>
                            )}
                            {tableData.admin_approve_status !== "rejected" && (
                                <button
                                    className="btn primary"
                                    onClick={handleRejectClick}
                                >
                                    Reject
                                </button>
                            )}
                        </div>
                    </td>
                </tr>
            );
        });
    };

    return (
        <table className="Data-Table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>FIRST NAME</th>
                    <th>DESIGNATION</th>
                    <th>DIVISION</th>
                    <th>QUALIFICATION</th>
                    <th>CONTACT NUMBER</th>
                    <th>SUBSCRIPTION</th>
                    <th>USER TYPE</th>
                    <th>STATUS</th>
                </tr>
            </thead>
            <tbody>{getTableBodyElement()}</tbody>
        </table>
    );
}

export default DataTable;