/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import './main.scss';

class Icon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let iconName = 'blank';
    const iconNames = ['dashboard', 'home', 'settings', 'menu', 'user', 'down-arrow', 'download', 'next', 'edit', 'close', 'image', 'insurance',
      'add-file'];
    const {
      name, color, className, help, onClick,
    } = this.props;

    if (iconNames.indexOf(name.toLowerCase()) >= 0) {
      iconName = name.toLowerCase();
    }

    const iconPath = (`${window.location.origin}/images/${iconName}.svg`);
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        className={`c-icon ds-ib al-c-t ${className || ''}`}
        title={help || ''}
        onClick={onClick}
      >
        <span
          className={`icon ds-ib ${color || 'bg-color'}`}
          style={{
            WebkitMaskImage: `url(${iconPath})`,
            maskImage: `url(${iconPath})`,
          }}
        />
      </div>
    );
  }
}
Icon.propTypes = {
  name: PropTypes.any,
  color: PropTypes.any,
  className: PropTypes.any,
  help: PropTypes.any,
  onClick: PropTypes.any,
};

export default Icon;
