import React from "react";
import ToastIcon from "./ToastIcon";
import Icon from "../icon";
import "./Toast.scss";

function Toast({ message, closeToast, type }) {
  return (
    <div className={`toast d-flex-center ${type}`}>
      {type === "success" ? (
        <Icon name="radio-checked" />
      ) : (
        <Icon name="ic_information" />
      )}
      <div className="toast__message toast--center">{message}</div>
      <div className="toast__close toast--center">
        <Icon name="reject_v3" onClick={closeToast} />
      </div>
    </div>
  );
}

export default Toast;
