import React from "react";
import Dropzone from "react-dropzone";
import Icon from "../icon";

class DropZone extends React.Component {
  constructor(props) {
    super(props);
    this.showIcon = props.showIcon || false;
    this.noClick = props.noClick || false;
  }
  render() {
    return (
      <div className={this.props.className}>
        <Dropzone
          noClick={this.noClick}
          onDrop={(acceptedFiles) => this.props.onDrop(acceptedFiles)}
          multiple={this.props.multiple}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="drop-zone" {...getRootProps()}>
              <input {...getInputProps()} />
              {this.showIcon && (
                <Icon
                  className="bg-color-g-l-2"
                  name="upload"
                  help="Upload Image"
                />
              )}
              {this.props.showCenterText && (
                <div className="upload-instruction font-small">
                  Click Here or Drag & Drop to upload image
                </div>
              )}
              {this.props.children}
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

export default DropZone;
