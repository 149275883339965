import React from 'react';
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Dashboard from './Views/dashboard';
import UserDetail from './Views/userdetail';
import AddUser from './Views/add-user';



const routes = () => (
  <Switch>
    <Route exact path="/user-detail/:userId" component={UserDetail} />
    <Route path="/add-user/:welfare/:userId" component={AddUser} />
    <Route path="/add-user/:userId" component={AddUser} />
    <Route path="/add-user" component={AddUser} />
    <Route path="/dashboard" component={Dashboard} />
    <Redirect from="/" to="/dashboard" />
    
  </Switch>
);

export default routes;
