import React from 'react';
import Icon from '../icon';
import { withRouter } from "react-router-dom";
import './main.scss';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  logOut() {
    localStorage.removeItem('userdetails')
    this.props.history.push(`/login`);
  }
  render() {
    const userDetail = JSON.parse(localStorage.getItem("userdetails"));
    const userData = userDetail.userData;
    return (
      <div className="Navbar">
        <div className="header">TNEBTPAS</div>
        <div className="username">
          Hi, {userData && userData.name}
          <Icon name="download" />
        </div>
        <div className="logout" onClick={() => this.logOut()}>Logout</div>
      </div>
    );
  }
}

export default withRouter(Navbar);
