import React from "react";
import { withRouter, Route, Redirect, Link } from "react-router-dom"; // Import Link

import Axios from "axios";
import TextField from "@material-ui/core/TextField";

import { APIS } from "../api/api";
import { Hidden } from "@material-ui/core";

const CancelToken = Axios.CancelToken;

class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      update: false,
      redirectUrl: undefined,
      loader: false,
      showSignin: false,
      name: "",
      password: "",
    };
    this.error = {};
    this.sign_out = this.sign_out.bind(this);
  }
  signIn() {
    const { name, password } = this.state;
    this.setState({ loader: true });
    let APIURL = APIS.get_url("SIGN_IN");
    let XHR = Axios({
      method: "post",
      url: APIURL,
      data: { name, password },
      responseType: "json",
    })
      .then((response) => {
        this.setState({ loader: false });
        if (response.data) {
          localStorage.setItem(
            "userdetails",
            JSON.stringify({
              name: name,
              password: password,
              userData: response.data,
            })
          );
          this.props.history.push("/");
        }
      })
      .catch((error) => {
        this.error.messge = "Something went wrong. Please try again later";
        this.setState({ loader: false });
      });

    this.setState({ loader: true });
  }

  sign_out() {
    let APIURL = APIS.get_url("SIGN_OUT");
    let XHR = Axios({
      method: "post",
      url: APIURL,
      responseType: "json",
      timeout: 60 * 1000,
      cancelToken: new CancelToken((c) => {
        this.asynReq.push(c);
      }),
    })
      .then((response) => {
        this.setState({ redirectUrl: "/" });
      })
      .catch((error) => {
        this.setState({ redirectUrl: "/" });
      });
  }

  updateFormValue(e, key) {
    this.setState({ [key]: e.target.value });
  }
  render() {
    const content = (
      <div className="login">
        <div className="header">
          <h3>Login</h3>
        </div>
        <div className="text-field">
          <TextField
            type="text"
            name="email"
            placeholder="Email"
            variant="outlined"
            size="small"
            onChange={(e) => this.updateFormValue(e, "name")}
          />
        </div>
        <div className="text-field">
          <TextField
            type="password"
            name="password"
            placeholder="Password"
            variant="outlined"
            size="small"
            onChange={(e) => this.updateFormValue(e, "password")}
          />
        </div>
        <div className="btn-holder">
          <button size="normal" className="btn" onClick={() => this.signIn()}>
            Sign In
          </button>
          <Link to="/add-user" className="btn" style={{ marginLeft: '10px' }}>
            Sign Up
          </Link>
        </div>
      </div>
    );
    return (
      <div className="w-100 d-flex">
        {this.state.loader && <div className="loader"></div>}
        <div className="w-70" style={{ height: "100%", overflow: "hidden" }}>
          <img
            style={{ height: "100%" }}
            src={`${window.location.origin}/images/login.jpg`}
          />
        </div>
        <div className="w-30">{content}</div>
      </div>
    );
  }
}

export default withRouter(Signin);