/* eslint-disable class-methods-use-this */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import '../Scss/add-user.scss';
import { APIS } from '../api/api';
import DropZone from '../Components/dropzone';
import Icon from '../Components/icon';
import DropDown from '../Components/dropdown';
import { districts } from '../constants/district';
import { divisions } from '../constants/division';
import { toastEmitter } from '../Utils/CommonFunction';

class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: null,
            nestedWorkHistoryCount: 0,
            profileImageSrc: '',
            formElements: [],
            welfare: false,
            welfarePutMethod: false,
            updatedKey: [],
            work_history: [
                {
                    doj: '',
                    dor: '',
                    designation: '',
                    office_address: '',
                    division: '',
                },
            ],
        };
    }

    componentDidMount() {
        const { match } = this.props;
        const userDetailsString = localStorage.getItem('userdetails');
        let userData = null;

        if (userDetailsString) {
            try {
                const userDetail = JSON.parse(userDetailsString);
                userData = userDetail.userData;
            } catch (error) {
                console.error("Error parsing userdetails from localStorage:", error);
            }
        }

        const routerParams = match?.params;
        const userId = routerParams?.userId;
        const welfare = routerParams?.welfare;
        let nominees;
        let formElements = [
            {
                formHeader: 'Personal Details',
                formFields: [
                    { label: 'First name', key: 'first_name' },
                    { label: 'Last name', key: 'last_name' },
                    { label: 'DOB', key: 'dob', type: 'date' },
                    {
                        label: 'Relationship',
                        key: 'relationship',
                        type: 'dropdown',
                        options: [
                            { value: 'S/O', label: 'S/O' },
                            { value: 'D/O', label: 'D/O' },
                            { value: 'W/O', label: 'W/O' },
                            { value: 'H/O', label: 'H/O' },
                        ],
                    },
                    { label: 'Father/Husband Name', key: 'nominee_name' },
                ],
            },
            {
                formHeader: 'Contact Details',
                formFields: [
                    { label: 'Contact Number', key: 'contact_number' },
                    { label: 'Email', key: 'email' },
                    { label: 'Residential Address', key: 'residential_address' },
                    { label: 'City', key: 'city', type: 'dropdown', options: districts },
                    { label: 'Pincode', key: 'pincode' },
                ],
            },
            {
                formHeader: 'Other Details',
                formFields: [
                    { label: 'Enrollment Date', key: 'enrollment_date', type: 'date' },
                    { label: 'Education Level', key: 'qualification' },
                    {
                        label: 'Auto subscription',
                        key: 'subscription',
                        type: 'dropdown',
                        options: [{ label: 'Auto', value: true }],
                    },
                    { label: 'Blood Group', key: 'blood_group' },
                    {
                        label: 'Gender',
                        key: 'gender',
                        type: 'dropdown',
                        options: [
                            { value: 'Male', label: 'Male' },
                            { value: 'Female', label: 'Female' },
                        ],
                    },
                ],
            },
        ];

        if (userData && userData.user_type === 'super_admin') {
            const adminOptions = {
                formHeader: 'Admin Options',
                formFields: [
                    {
                        label: 'User Type',
                        key: 'user_type',
                        type: 'dropdown',
                        options: [
                            { label: 'Admin', value: 'admin' },
                            { label: 'Super Admin', value: 'super_admin' },
                            { label: 'District Admin', value: 'district_admin' },
                        ],
                    },
                ],
            };
            formElements.push(adminOptions);
        }

        if (welfare) {
            nominees = [{}, {}, {}, {}, {}];
            formElements = [
                {
                    formHeader: 'Details',
                    formFields: [
                        { label: 'GPF / CPS NO', key: 'gps_cps_no' },
                        { label: 'Aadhar No', key: 'aadhar_no' },
                        { label: 'Permanent Address', key: 'permanent_address' },
                        {
                            label: 'Marital status',
                            key: 'married',
                            type: 'dropdown',
                            options: ['Single', 'Married'],
                        },
                        { label: 'Nominee Name', key: 'hushband_wife_name' },
                    ],
                },
            ];
        }

        this.setState({ userId, formElements, welfare, nominees }, () => {
            if (userId) this.getUserDetails();
        });
    }

    // ... (rest of the methods: getUserDetails, getWelfare, getAddExtraWorkBtn, updateFormValue, addExtraWork, uploadProfileImage, removeFile, handleOptionChange, updateUser, adduser, downloadIdCard, addWelfare, getFormElement, renderFormFields, renderFormCard, renderFormHolder, renderWelfareElement, updateWorkdetails, getWorkDetailFormElement, renderWorkDetails)
    getUserDetails() {
        const { userId } = this.state;
        const APIURL = APIS.get_url('GET_USERDETAILS', userId);
        this.setState({ loader: true });
        Axios({
            method: 'get',
            url: APIURL,
            responseType: 'json',
            timeout: 60 * 1000,
            headers: {
                'X-Username': 'santhosh',
            },
        }).then((res) => {
            if (res.data) {
                const permanent_address = res.data?.residential_address
                this.setState({ ...res.data, permanent_address }, () => {
                    if (this.state.welfare) {
                        this.getWelfare();
                    }
                });
            }
            this.setState({ loader: false });
        });
    }
    getWelfare() {
        const { user } = this.state;
        this.setState({ loader: true });
        const APIURL = APIS.get_url('GET_WELFARE', user);
        Axios({
            method: 'get',
            url: APIURL,
            responseType: 'json',
            timeout: 60 * 1000,
            headers: {
                'X-Username': 'santhosh',
            },
        })
            .then((res) => {
                if (res.data) {
                    console.log('res.data', res.data);
                    const permanent_address = res.data.permanent_address ? res.data.permanent_address : this.state.permanent_address
                    this.setState({ ...res.data, permanent_address, user: this.state.user });
                    if (res.data?.user) {
                        this.setState({ welfarePutMethod: true })
                    }
                }
                this.setState({ loader: false });
            })
            .catch((err) => {
                if (user) {
                    this.setState({
                        permanent_address: this.state.residential_address,
                        hushband_wife_name: this.state.nominee_name,
                    });
                }
                this.setState({ loader: false });
            });
    }
    getAddExtraWorkBtn() {
        return (
            <div className='add-extra-value'>
                <button className='btn primary' onClick={() => this.addExtraWork()}>
                    Add Extra Work
                </button>
            </div>
        );
    }
    updateFormValue(e, key, parentKeyvalue, nestedKeyvalue, index) {
        const updatedKeyCpy = [...this.state.updatedKey];
        updatedKeyCpy.push(key);
        if (parentKeyvalue && nestedKeyvalue) {
            const parentValue = this.state[parentKeyvalue] || {};
            parentValue[nestedKeyvalue] = parentValue[nestedKeyvalue] || {};
            parentValue[nestedKeyvalue][key] = e.target.value;
            this.setState({
                [parentKeyvalue]: parentValue,
                updatedKey: updatedKeyCpy,
            });
        } else if (index !== undefined) {
            const parentValue = this.state.nominees || [];
            const indexValue = parentValue?.[index] || {};
            indexValue[key] = e.target.value;
            if (!parentValue[index]) {
                parentValue[index] = {};
            }
            parentValue[index] = indexValue;
            this.setState({ nominees: parentValue, updatedKey: updatedKeyCpy });
        } else {
            this.setState({ [key]: e.target.value, updatedKey: updatedKeyCpy });
        }
    }

    addExtraWork() {
        const { work_history } = this.state;
        work_history.push({
            doj: '',
            dor: '',
            designation: '',
            office_address: '',
            division: '',
        });
        this.setState({ work_history });
    }

    uploadProfileImage(imageFile) {
        const file = imageFile[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            const updatedKeyCpy = [...this.state.updatedKey];
            updatedKeyCpy.push('profile_image');
            this.setState({
                profileImageSrc: reader.result,
                profile_image: file,
                updatedKey: updatedKeyCpy,
            });
        }.bind(this);
    }
    removeFile() {
        this.setState({ profileImageSrc: undefined, profile_image: undefined });
    }
    handleOptionChange(value) {
        console.log(value);
    }
    updateUser() {
        this.setState({ loader: true });
        const APIURL = APIS.get_url('UPDATE_USER_DETAILS', this.state.userId);
        let { work_history, updatedKey } = this.state;
        const currentWorkHistrory = work_history[0];
        let formData = new FormData();
        console.log({ updatedKey });
        updatedKey.forEach((key) => {
            console.log({ key, val: this.state[key] });
            if (key !== 'profileImageSrc' && key !== 'work_history') {
                formData.append(key, this.state[key]);
            } else if (key === 'work_history') {
                formData.append('work_history', JSON.stringify(work_history));
                formData.append('doj', currentWorkHistrory['doj']);
                formData.append('designation', currentWorkHistrory['designation']);
                formData.append(
                    'office_address',
                    currentWorkHistrory['office_address']
                );
                formData.append('division', currentWorkHistrory['division']);
            }
        });
        Axios({
            method: 'put',
            url: APIURL,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            responseType: 'json',
        }).then((res) => {
            if (res) {
                toastEmitter('User Data updated');
            }
            this.setState({ loader: false });
        });
    }
    adduser() {
        this.setState({ loader: true });
        const APIURL = APIS.get_url('ADD_USER');
        let { work_history } = this.state;
        const currentWorkHistrory = work_history[0];
        let formData = new FormData();
        const resetState = {};
        Object.keys(this.state).forEach((key) => {
            if (
                key &&
                key !== 'formElements' &&
                key !== 'profileImageSrc' &&
                key !== 'work_history'
            ) {
                formData.append(key, this.state[key]);
            }
            if (key !== 'formElements') {
                if (key === 'work_history') {
                    resetState[key] = [
                        {
                            doj: '',
                            dor: '',
                            designation: '',
                            office_address: '',
                            division: '',
                        },
                    ];
                } else {
                    resetState[key] = '';
                }
            }
        });
        formData.append('work_history', JSON.stringify(work_history));
        formData.append('doj', currentWorkHistrory['doj']);
        formData.append('designation', currentWorkHistrory['designation']);
        formData.append('office_address', currentWorkHistrory['office_address']);
        formData.append('division', currentWorkHistrory['division']);

        formData.append('name', this.state['first_name'] + this.state['last_name']);
        Axios({
            method: 'post',
            url: APIURL,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            responseType: 'json',
        }).then((res) => {
            this.setState({ loader: false, ...resetState });
        });
    }
    downloadIdCard() {
        window.location.href = this.state.id_card;
    }
    addWelfare() {
        const payloadData = {};
        const {
            nominees,
            gps_cps_no,
            aadhar_no,
            permanent_address,
            married,
            hushband_wife_name,
            user,
            welfarePutMethod
        } = this.state;
        const payloadNominees = [];
        nominees.forEach((nominee) => {
            if (Object.keys(nominee).length > 0) {
                payloadNominees.push(nominee);
            }
        });
        payloadData['nominees'] = payloadNominees;
        payloadData['gps_cps_no'] = gps_cps_no;
        payloadData['aadhar_no'] = aadhar_no;
        payloadData['permanent_address'] = permanent_address;
        payloadData['married'] = married;
        payloadData['hushband_wife_name'] = hushband_wife_name;
        this.setState({ loader: true });
        const APIURL = APIS.get_url('ADD_WELFARE', user);
        Axios({
            method: welfarePutMethod ? 'put' : 'post',
            url: APIURL,
            data: payloadData,
            responseType: 'json',
        }).then((res) => {
            this.setState({ loader: false });
        });
    }
    getFormElement(element, parentKeyvalue, nestedKeyvalue, indexValue) {
        if (element.type === 'dropdown') {
            return (
                <DropDown
                    dropDownOptions={element.options}
                    label={element.label}
                    handleOptionChange={(e) => {
                        console.log('change', { e });
                        this.updateFormValue(
                            e,
                            element.key,
                            parentKeyvalue,
                            nestedKeyvalue,
                            indexValue
                        );
                    }}
                    selectedValue={
                        indexValue !== undefined
                            ? this.state?.nominees?.[indexValue]
                                ? this.state?.nominees?.[indexValue]?.[element.key]
                                : ''
                            : this.state[element.key]
                    }
                />
            );
        } else {
            return (
                <TextField
                    label={element?.label}
                    type={element?.type}
                    value={
                        indexValue !== undefined
                            ? this.state?.nominees?.[indexValue]
                                ? this.state?.nominees?.[indexValue]?.[element.key]
                                : ''
                            : this.state[element.key]
                    }
                    variant='outlined'
                    defaultValue={element.type === 'date' ? '0000-00-00' : ''}
                    onChange={(e) =>
                        this.updateFormValue(
                            e,
                            element.key,
                            parentKeyvalue,
                            nestedKeyvalue,
                            indexValue
                        )
                    }
                    size='small'
                    InputLabelProps={{ shrink: element.type || this.state.userId }}
                />
            );
        }
    }
    renderFormFields(formFields, parentKeyvalue, nestedKeyvalue, indexValue) {
        return formFields.map((element) =>
            this.getFormElement(element, parentKeyvalue, nestedKeyvalue)
        );
    }
    renderFormCard(element) {
        return (
            <div className='form-holder'>
                <div className='form-holder-header'>{element.formHeader}</div>
                {this.renderFormFields(
                    element.formFields,
                    element.parentKeyvalue,
                    element.nestedKeyvalue
                )}
            </div>
        );
    }

    renderFormHolder() {
        const { formElements } = this.state;
        return formElements.map((element) => this.renderFormCard(element));
    }
    renderWelfareElement() {
        const tableConstants = [
            {
                label: 'Name',
                key: 'name',
            },
            {
                label: 'Relationship',
                type: 'dropdown',
                options: ['Father', 'Mother', 'Spouse', 'Son', 'Daughter'],
                key: 'relationship',
            },
            {
                label: 'D.O.B',
                type: 'date',
                key: 'dob',
            },
        ];

        return (
            <table className='Data-Table'>
                <thead>
                    <tr>
                        {tableConstants.map((constant) => (
                            <th>{constant.label}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {[0, 1, 2, 3, 4].map((row) => (
                        <tr>
                            {tableConstants.map((constant) => (
                                <td>
                                    {this.getFormElement(constant, 'welfare', undefined, row)}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    updateWorkdetails(e, key, index) {
        const { work_history, updatedKey } = this.state;
        const updatedKeyCpy = [...updatedKey];
        if (updatedKeyCpy.indexOf('work_history') < 0) {
            updatedKeyCpy.push('work_history');
        }

        work_history[index][key] = e.target.value;
        this.setState({ work_history, updatedKey: updatedKeyCpy });
    }
    getWorkDetailFormElement(label, value, key, index, type, options) {
        switch (type) {
            case 'dropdown':
                return (
                    <DropDown
                        dropDownOptions={options}
                        label={label}
                        handleOptionChange={(e) => this.updateWorkdetails(e, key, index)}
                        selectedValue={value}
                    />
                );
                break;
            default:
                return (
                    <TextField
                        label={label}
                        type={type}
                        value={value}
                        variant='outlined'
                        onChange={(e) => this.updateWorkdetails(e, key, index)}
                        size='small'
                        InputLabelProps={{ shrink: type }}
                    />
                );
        }
    }

    renderWorkDetails() {
        const { work_history } = this.state;
        return (
            work_history &&
            work_history.map((workdetail, idx) => (
                <div className='form-holder'>
                    <div className='form-holder-header'>Work Details</div>
                    {this.getWorkDetailFormElement(
                        'Joining Date',
                        workdetail.doj,
                        'doj',
                        idx,
                        'date'
                    )}
                    {idx !== 0 &&
                        this.getWorkDetailFormElement(
                            'Relieving Date',
                            workdetail.dor,
                            'dor',
                            idx,
                            'date'
                        )}
                    {this.getWorkDetailFormElement(
                        'Designation',
                        workdetail.designation,
                        'designation',
                        idx
                    )}

                    {this.getWorkDetailFormElement(
                        'Circle Of Working ',
                        workdetail.division,
                        'division',
                        idx,
                        'dropdown',
                        divisions.map((div) => div.edc)
                    )}
                    {this.getWorkDetailFormElement(
                        'Office Address',
                        workdetail.office_address,
                        'office_address',
                        idx,
                        'dropdown',
                        (divisions.find((div) => div.edc === workdetail.division) || {})
                            .sections
                    )}
                    {idx === work_history.length - 1 && this.getAddExtraWorkBtn()}
                </div>
            ))
        );
    }

    render() {
        const profileImgSrc = this.state.profileImageSrc
            ? this.state.profileImageSrc
            : this.state.profile_image;
        return (
            <div className='add-user'>
                {this.state.loader && <div className='loader'></div>}
                <div className='content-area'>
                    <div className='form-navbar'>
                        <div className='page-header'>Registration Form</div>
                        <div className='navbar-info'>Enter user info to be registered</div>
                        {profileImgSrc ? (
                            <div className='csv-dropzone-solid'>
                                <img src={profileImgSrc} />
                                <div
                                    className='close'
                                    onClick={() => {
                                        this.removeFile();
                                    }}
                                >
                                    <Icon name='close' />
                                </div>
                            </div>
                        ) : (
                            <div className='csv-dropzone'>
                                <DropZone
                                    showCenterText={true}
                                    showIcon={true}
                                    onDrop={(files) => this.uploadProfileImage(files)}
                                />
                            </div>
                        )}
                        {this.state.id_card && (
                            <div className='id__card' onClick={() => this.downloadIdCard()}>
                                {' '}
                                Download Id Card
                            </div>
                        )}
                    </div>
                    <div className='form-area'>
                        {this.renderFormHolder()}
                        {!this.state.welfare && this.renderWorkDetails()}
                        {this.state.welfare && this.renderWelfareElement()}
                    </div>
                </div>
                <div className='button-holder'>
                    <button className='btn'>Reset</button>
                    {this.state.welfare ? (
                        <button className='btn primary' onClick={() => this.addWelfare()}>
                            Submit
                        </button>
                    ) : (
                        <button
                            className='btn primary'
                            onClick={() => {
                                this.state.userId ? this.updateUser() : this.adduser();
                            }}
                        >
                            {this.state.userId ? 'Update' : 'Submit'}
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(AddUser);