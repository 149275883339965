import React from "react";
import Input from "../Input";

export default function DashboardFilter({
  data: { filterElements },
  methods: { onFilterChange },
}) {
  return (
    filterElements &&
    filterElements.map(
      (element) =>
        !element.restrictedAccess && (
          <Input
            element={element}
            valueChange={(value) => onFilterChange(value, element.key)}
          />
        )
    )
  );
}
